import useAuthenticatedQuery from '../hook/useAuthenticatedQuery';
import queryAsync from '../network/apiClient';
import { TechnologyType } from '../types/technology';

export const useTechnologyDropdown = () => {
  return useAuthenticatedQuery(
    ['technology-dropdown'],
    (token) => {
      return queryAsync<TechnologyType[]>({
        path: `/technologies/dropdown?fields=id,name,value`,
        type: 'Get',
        token,
      });
    },
    {
      initialData: [],
    }
  );
};
