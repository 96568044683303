import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { NodeProps } from 'reactflow';

import * as s from './styles';
import DBTableNodeRow from '../DBTableNodeRow';
import Icon from '../../Icon';
import { useDiagramSlice } from '../../../store';
import { JsonDBTableType } from '../../../types';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS, PAGE } from '../../../constants/analyticsEvent';

const DBTableNode: React.FC<NodeProps<JsonDBTableType>> = ({
  data: table,
  id,
}) => {
  const { track } = useAnalytics();

  const setSelectedNode = useDiagramSlice((state) => state.setSelectedNode);

  const handleEditTable = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.ICON_BUTTON,
      label: 'icon_edit_table',
      page: PAGE.PROJECT_DETAILS,
    });
    setSelectedNode(id);
  };

  return (
    <>
      <s.Container variant="outlined">
        <Stack>
          <s.Header direction={'row'} alignItems={'center'}>
            <Typography fontSize={20} fontWeight={'bold'}>
              {table.name}
            </Typography>
            <IconButton sx={{ padding: 0.25 }} onClick={handleEditTable}>
              <Icon
                tooltip={`Edit ${table.name}`}
                iconName="edit_note"
                size={20}
              />
            </IconButton>
          </s.Header>
          <s.Content>
            {table.cols.map((column, index) => (
              <DBTableNodeRow
                key={index}
                tableName={table.name}
                data={column}
              />
            ))}
          </s.Content>
        </Stack>
      </s.Container>
    </>
  );
};

export default DBTableNode;
