import useAuthenticatedMutation from '../hook/useAuthenticateMutation';
import useAuthenticatedQuery from '../hook/useAuthenticatedQuery';
import queryAsync from '../network/apiClient';
import { CreateZipFileType, ZipFileType } from '../types';

export const useZipFiles = (
  page: number,
  projectId: string,
  enabled: boolean
) => {
  return useAuthenticatedQuery(
    ['zip-files', `${page}`, `${projectId}`],
    (token) => {
      return queryAsync<{
        result: ZipFileType[];
        total: number;
      }>({
        path: `/zip-files?page=1&limit=100&projectId=${projectId}`,
        type: 'Get',
        token,
      });
    },
    {
      refetchInterval: (data) => {
        let itemsInProgress = false;
        if (data && data.result) {
          itemsInProgress =
            data.result.length > 0 ? data?.result[0].status === 0 : false;
        }
        return itemsInProgress ? 3000 : false;
      },
      enabled,
    }
  );
};

export const useCreateZipFile = () => {
  return useAuthenticatedMutation(['create-zip-file'], (token, params) => {
    return queryAsync<CreateZipFileType>({
      path: `/zip-files`,
      type: 'POST',
      data: { ...params },
      token,
    });
  });
};

export const useDeleteZipFile = (projectId: string) => {
  return useAuthenticatedMutation(['delete-zip-file'], (token) => {
    return queryAsync({
      path: `/zip-files/${projectId}`,
      type: 'DELETE',
      token,
    });
  });
};
