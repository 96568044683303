import { Buffer } from 'buffer';
import { v4 as uuidV4 } from 'uuid';

export const getUniqueId = () => {
  return uuidV4();
};

export const base64ToObject = (base64String: string): any => {
  if (!base64String) return {};
  const buffer = Buffer.from(base64String, 'base64');
  const jsonString = buffer.toString('utf8');
  return JSON.parse(jsonString);
};
