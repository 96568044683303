import useAuthenticatedMutation from '../hook/useAuthenticateMutation';
import queryAsync from '../network/apiClient';

export const useCreateFeedback = () => {
  return useAuthenticatedMutation(['feedback'], (token, params) => {
    return queryAsync<string>({
      path: `/feedbacks`,
      type: 'POST',
      data: { ...params },
      token,
    });
  });
};
