import { useQueryClient } from '@tanstack/react-query';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { appRoutes, common } from '../../constants';
import PageLoader from '../PageLoader';
import { useMyAccount, useRefreshToken } from '../../queries';
import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import { useCookies } from 'react-cookie';

const AuthenticateGuard = () => {
  const { identify } = useAnalytics();
  const queryClient = useQueryClient();

  const location = useLocation();

  const accessToken = localStorage.getItem(common.KEY_ACCESS_TOKEN);

  const [_, setCookie] = useCookies(['_anaId']);

  const { isFetching, isSuccess, isError, isRefetching, data } = useMyAccount(
    !!accessToken
  );
  const {
    isFetching: isFetchingOnRefreshToken,
    isError: isErrorOnRefreshToken,
    isSuccess: isSuccessOnRefreshToken,
    refetch: refreshToken,
    data: refreshTokenData,
  } = useRefreshToken(false);

  useEffect(() => {
    if (isError) {
      refreshToken();
    }
  }, [isError]);

  useEffect(() => {
    if (data?.id) {
      setCookie('_anaId', data.id, {
        domain: import.meta.env.DEV ? 'localhost' : '.code99.io',
      });
      identify(data.id, {
        name: data?.name,
        email: data?.email,
      });
    }

    if (isSuccessOnRefreshToken && refreshTokenData) {
      queryClient.setQueryData(
        [common.KEY_ACCESS_TOKEN],
        () => refreshTokenData.accessToken
      );
      localStorage.setItem(
        common.KEY_ACCESS_TOKEN,
        refreshTokenData.accessToken
      );
      queryClient.setQueryData(
        [common.KEY_REFRESH_TOKEN],
        () => refreshTokenData.refreshToken
      );
      localStorage.setItem(
        common.KEY_REFRESH_TOKEN,
        refreshTokenData.refreshToken
      );
    } else if (isErrorOnRefreshToken) {
      queryClient.removeQueries([common.KEY_ACCESS_TOKEN]);
      localStorage.removeItem(common.KEY_ACCESS_TOKEN);
      queryClient.removeQueries([common.KEY_REFRESH_TOKEN]);
      localStorage.removeItem(common.KEY_REFRESH_TOKEN);
    }
  }, [isSuccessOnRefreshToken, isErrorOnRefreshToken]);

  if ((isFetching || isFetchingOnRefreshToken) && !isRefetching) {
    return <PageLoader />;
  } else {
    if (isSuccess) {
      return <Outlet />;
    } else {
      return (
        <Navigate
          to={appRoutes.LOGIN}
          state={{ from: location.pathname, search: location.search }}
        />
      );
    }
  }
};

export default AuthenticateGuard;
