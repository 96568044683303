import { Stack, Typography } from '@mui/material';

import * as s from './styles';
import Icon from '../../Icon';
import { yellow } from '@mui/material/colors';
import { Handle, Position } from 'reactflow';
import { JsonDBTableColumnType } from '../../../types';
import { memo } from 'react';

export interface DBTableNodeRowType {
  tableName: string;
  data: JsonDBTableColumnType;
}

const DBTableNodeRow: React.FC<DBTableNodeRowType> = ({
  tableName,
  data: { name, type, required, primary, index, fk },
}) => {
  return (
    <s.Container direction={'row'}>
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <Icon
          iconName={required ? 'circle' : 'radio_button_checked'}
          size={8}
          tooltip={required ? 'Required' : 'Nullable'}
        />
        <Typography fontSize={16}>{name}</Typography>
        {primary && (
          <Icon iconName="key" size={14} color={yellow[800]} tooltip="PK" />
        )}
        {!!fk && <Icon iconName="key" size={14} tooltip="FK" />}
        {index && <Icon iconName="list" size={14} tooltip="Index" />}
      </Stack>
      <Typography fontSize={14}>{type}</Typography>
      {primary && (
        <Handle
          type="source"
          position={Position.Right}
          id={`${tableName}.${name}`}
          isConnectable={false}
        />
      )}
      {!!fk && (
        <Handle
          type="target"
          position={Position.Left}
          id={`${tableName}.${name}`}
          isConnectable={false}
        />
      )}
    </s.Container>
  );
};

export default memo(DBTableNodeRow);
