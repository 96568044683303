import { Box } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';

const styledForAccountMenu: SxProps = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
};

const styledForMenuIcon: SxProps = {
  marginRight: 5,
  display: {
    lg: 'none',
  },
};

const SpaceExpander = styled(Box)`
  flex-grow: 1;
`;

const styledForDeviceList: SxProps = {
  mr: 1,
};

export {
  styledForAccountMenu,
  styledForMenuIcon,
  SpaceExpander,
  styledForDeviceList,
};
