import { Grid, TextField, Typography, Link as MuiLink } from '@mui/material';
import * as s from './styles';
import { Controller, useForm } from 'react-hook-form';
import { RegisterType } from '../../types';
import { appRoutes } from '../../constants';
import { Link, useParams } from 'react-router-dom';
import { useRegister } from '../../queries';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Alert, CircularProgress } from '../../components';
import {
  useCheckoutSession,
  useStripeRegister,
} from '../../queries/stripeQueries';
import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const RegisterSchema = yup.object<RegisterType>().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Enter valid email address')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const Register = () => {
  const { page, track } = useAnalytics();
  const { checkoutSessionId } = useParams();

  const defaultValues: RegisterType = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<RegisterType>({
    defaultValues,
    resolver: yupResolver(RegisterSchema),
  });

  const registerMutation = useRegister();
  const stripeRegisterMutation = useStripeRegister();

  const { data, isFetching, isError, isSuccess, error } =
    useCheckoutSession(checkoutSessionId);

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('email', data.email);
    }
  }, [data]);

  const onSingUp = (data: RegisterType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'register',
      page: PAGE.REGISTER,
    });

    if (checkoutSessionId) {
      stripeRegisterMutation.mutate(
        { ...data, checkoutSessionId },
        {
          onSuccess: () => {
            track(EVENTS.SUCCESS, {
              page: PAGE.REGISTER,
              type: 'via_stripe',
              action: 'register',
            });
            reset();
          },
          onError: () => {
            track(EVENTS.ERROR, {
              page: PAGE.REGISTER,
              type: 'via_stripe',
              action: 'register',
            });
          },
        }
      );
    } else {
      registerMutation.mutate(
        { ...data },
        {
          onSuccess: () => {
            track(EVENTS.SUCCESS, {
              page: PAGE.REGISTER,
              type: 'normal',
              action: 'register',
            });
            reset();
          },
          onError: () => {
            track(EVENTS.ERROR, {
              page: PAGE.REGISTER,
              type: 'normal',
              action: 'register',
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    page({
      title: PAGE.REGISTER,
    });
  }, []);

  if (isFetching) {
    return (
      <s.Container>
        <CircularProgress />
      </s.Container>
    );
  }

  if (
    checkoutSessionId &&
    (stripeRegisterMutation.isError || stripeRegisterMutation.isSuccess)
  ) {
    return (
      <Alert
        isError={stripeRegisterMutation.isError}
        isSuccess={stripeRegisterMutation.isSuccess}
        error={stripeRegisterMutation.error}
        successMessage="Thank you for registering, please check your email and verify it."
      />
    );
  }

  if (registerMutation.isError || registerMutation.isSuccess) {
    return (
      <Alert
        isError={registerMutation.isError}
        isSuccess={registerMutation.isSuccess}
        error={registerMutation.error}
        successMessage="Thank you for registering, please check your email and verify it."
      />
    );
  }

  return (
    <s.Container>
      <Typography variant="h4">Register</Typography>
      {!!checkoutSessionId && (
        <Alert
          isError={isError}
          isSuccess={isSuccess}
          error={error}
          successMessage="Thank you for subscribing, Now just set password for your account."
        />
      )}
      <s.FormWrapper component="form" onSubmit={handleSubmit(onSingUp)}>
        <Controller
          control={control}
          name="name"
          defaultValue={defaultValues.name}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                margin="normal"
                fullWidth
                label="Name"
                autoFocus
                error={!!errors.name?.message}
                helperText={errors.name?.message}
                disabled={!!checkoutSessionId}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="email"
          defaultValue={defaultValues.email}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label="Email Address"
              autoComplete="email"
              error={!!errors.email?.message}
              helperText={errors.email?.message}
              disabled={!!checkoutSessionId}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          defaultValue={defaultValues.password}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              autoFocus={!!checkoutSessionId}
              label="Password"
              type="password"
              error={!!errors.password?.message}
              helperText={errors.password?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          defaultValue={defaultValues.confirmPassword}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label="Confirm Password"
              type="password"
              error={!!errors.confirmPassword?.message}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />
        {/* <FormControlLabel
          control={<Checkbox value="termsCondition" color="primary" />}
          label="Agree to Terms and Conditions"
        /> */}
        <s.RegisterButton
          type="submit"
          fullWidth
          variant="contained"
          size="large"
        >
          Register
        </s.RegisterButton>
        <Grid container>
          <Grid item xs>
            <MuiLink
              component={Link}
              to={appRoutes.LOGIN}
              underline="none"
              onClick={() => {
                track(EVENTS.CLICK, {
                  category: CATEGORY.LINK,
                  label: 'back_to_login',
                  page: PAGE.REGISTER,
                });
              }}
            >
              Back to Login
            </MuiLink>
          </Grid>
        </Grid>
      </s.FormWrapper>
    </s.Container>
  );
};

export default Register;
