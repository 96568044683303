import { JsonDBTableColumnType } from '../types';

export const ID_COL: JsonDBTableColumnType = {
  name: 'id',
  type: 'integer',
  primary: true,
  index: true,
  required: true,
  defaultType: {
    value: 'AUTO_INCREMENT',
    note: 'Configured Auto-generated',
  },
};

export const CREATED_COL: JsonDBTableColumnType = {
  name: 'createdAt',
  type: 'datetime',
  required: true,
  defaultType: {
    value: 'CREATED_AT',
    note: 'Configured by default',
  },
};

export const UPDATED_COL: JsonDBTableColumnType = {
  name: 'updatedAt',
  type: 'datetime',
  required: true,
  defaultType: {
    value: 'UPDATED_AT',
    note: 'Configured by default',
  },
};

const NEW_TABLE_COLUMNS: JsonDBTableColumnType[] = [
  ID_COL,
  CREATED_COL,
  UPDATED_COL,
];

export { NEW_TABLE_COLUMNS };
