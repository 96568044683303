import { Stack, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const Container = styled(Stack)`
  padding: 6px 12px;
  border-top: 0.5px solid ${blueGrey[700]};
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${blueGrey[700]};
  }
  position: relative;
`;

export { Container };
