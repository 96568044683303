import { Card, styled } from '@mui/material';

const Container = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;
  & :active {
    outline: none;
  }
`;

export { Container };
