import { useState } from 'react';
import { Button, MenuItem, Stack } from '@mui/material';
import * as yup from 'yup';
import { produce } from 'immer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import * as s from './styles';
import DropDownWithLabel from './DropDownWithLabel';
import {
  JsonDBReferenceColumnType,
  JsonDBTableColumnType,
} from '../../../types';
import { useDiagramSlice } from '../../../store';
import ModalWithTitle from '../../ModalWithTitle';
import { useUpdateEffect } from '../../../hook';
import { indigo } from '../../../theme/colors';

const relationTypes = [
  {
    value: 'one-to-one',
    label: 'One to One',
  },
  {
    value: 'one-to-many',
    label: 'One to Many',
  },
  {
    value: 'many-to-one',
    label: 'Many to One',
  },
  {
    value: 'many-to-many',
    label: 'Many to Many',
  },
];

const selectColumn: JsonDBTableColumnType = {
  name: 'Select Column',
  type: 'String',
  primary: true,
  index: true,
  required: true,
};
const DEFAULT_COL_VALUE = 'Select Column';

type RelationFormType = JsonDBReferenceColumnType;

export interface DBRelationModalProps {
  open: boolean;
  defaultValues?: RelationFormType;
  currentTableName: string;
  onClose: () => void;
  onDeleteKey: () => void;
  onSaveChanges: (data: RelationFormType) => void;
}

const schema = yup.object<RelationFormType>().shape({
  table: yup.string().required('Table is required'),
  refCol: yup
    .string()
    .notOneOf([DEFAULT_COL_VALUE])
    .required('Reference Column is required'),
  labelCol: yup
    .string()
    .notOneOf([DEFAULT_COL_VALUE])
    .required('Label Column is required'),
  type: yup.string().required('Relation Type is required'),
});

const DBRelationModal: React.FC<DBRelationModalProps> = ({
  open,
  defaultValues: values,
  currentTableName,
  onClose,
  onDeleteKey,
  onSaveChanges,
}) => {
  const nodes = useDiagramSlice((state) => state.nodes);

  let defaultValues: RelationFormType = {
    table: nodes.length > 0 ? nodes[0].data.name : '',
    refCol: DEFAULT_COL_VALUE,
    labelCol: DEFAULT_COL_VALUE,
    type: 'one-to-one',
  };

  let defaultColumns = [
    selectColumn,
    ...(nodes.length > 0 ? nodes[0].data.cols : []),
  ];
  if (values) {
    const tableIndex = nodes.findIndex(
      (node) => node.data.name === values.table
    );
    if (tableIndex > -1) {
      const newTable = nodes[tableIndex].data;
      const newColumns = produce(newTable.cols, (draft) => {
        draft.splice(0, 0, selectColumn);
      });

      defaultValues = values;
      defaultColumns = newColumns;
    }
  }

  const [columns, setColumns] =
    useState<JsonDBTableColumnType[]>(defaultColumns);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<RelationFormType>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const watchTable = watch('table');

  useUpdateEffect(() => {
    const tableIndex = nodes.findIndex((node) => node.data.name === watchTable);
    if (tableIndex > -1) {
      const newTable = nodes[tableIndex].data;
      const newColumns = produce(newTable.cols, (draft) => {
        draft.splice(0, 0, selectColumn);
      });

      setValue('refCol', DEFAULT_COL_VALUE);
      setValue('labelCol', DEFAULT_COL_VALUE);

      setColumns(newColumns);
    }
  }, [watchTable]);

  return (
    <ModalWithTitle
      open={open}
      title={values ? 'Edit Foreign Key' : 'Add Foreign Key'}
      renderAction={() => (
        <Stack
          flex={1}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={6}
        >
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={onDeleteKey}
          >
            Delete Key
          </Button>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <Button onClick={onClose} sx={{ color: indigo.light }} size="small">
              Cancel
            </Button>
            <Button variant="contained" size="small" type="submit">
              Save Changes
            </Button>
          </Stack>
        </Stack>
      )}
      form={{
        onSubmit: handleSubmit(onSaveChanges),
      }}
    >
      <s.Container spacing={3}>
        <Controller
          control={control}
          name={`table`}
          defaultValue={defaultValues.table}
          render={({ field }) => {
            return (
              <DropDownWithLabel
                label="Table"
                field={field}
                error={!!errors.table}
              >
                {nodes
                  .filter((x) => x.data.name !== currentTableName)
                  .map((node, index) => (
                    <MenuItem key={`${index}`} value={node.data.name}>
                      {node.data.name}
                    </MenuItem>
                  ))}
              </DropDownWithLabel>
            );
          }}
        />
        <Controller
          control={control}
          name={`refCol`}
          defaultValue={defaultValues.refCol}
          render={({ field }) => {
            return (
              <DropDownWithLabel
                label="Reference Column"
                field={field}
                error={!!errors.refCol}
              >
                {columns
                  .filter((x) => x.primary)
                  .map((column, index) => (
                    <MenuItem key={`${index}`} value={column.name}>
                      {column.name}
                    </MenuItem>
                  ))}
              </DropDownWithLabel>
            );
          }}
        />
        <Controller
          control={control}
          name={`labelCol`}
          defaultValue={defaultValues.labelCol}
          render={({ field }) => {
            return (
              <DropDownWithLabel
                label="Label Column"
                field={field}
                error={!!errors.labelCol}
              >
                {columns.map((column, index) => (
                  <MenuItem key={`${index}`} value={column.name}>
                    {column.name}
                  </MenuItem>
                ))}
              </DropDownWithLabel>
            );
          }}
        />
        <Controller
          control={control}
          name={`type`}
          defaultValue={defaultValues.type}
          render={({ field }) => {
            return (
              <DropDownWithLabel
                label="Relation Type"
                field={field}
                error={!!errors.type}
              >
                {relationTypes.map((relation, index) => (
                  <MenuItem key={`${index}`} value={relation.value}>
                    {relation.label}
                  </MenuItem>
                ))}
              </DropDownWithLabel>
            );
          }}
        />
      </s.Container>
    </ModalWithTitle>
  );
};

export default DBRelationModal;
