import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import {
  useCreateZipFile,
  useMyAccount,
  useProjectDetails,
  useZipFiles,
} from '../../queries';
import {
  Alert,
  CircularProgress,
  GenerateCodeForm,
  Icon,
  PageContainer,
  PageLoader,
  SubscriptionAlert,
} from '../../components';
import * as s from './styles';
import { CreateZipFileType } from '../../types/zipFile';
import React, { useEffect } from 'react';
import { config } from '../../constants';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const ProjectCode = () => {
  const { projectId: _id } = useParams();
  const projectId = _id || '';

  const { page, track } = useAnalytics();
  const navigate = useNavigate();

  const { data: myAccount, refetch: refetchMyAccount } = useMyAccount(false);

  const {
    data: project,
    isFetching,
    isRefetching,
  } = useProjectDetails(projectId, true);

  const { data: zipFiles, refetch: refetchZipFiles } = useZipFiles(
    0,
    projectId,
    true
  );

  const zipFileCreateMutation = useCreateZipFile();

  const handleGenerateCode = (data: CreateZipFileType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'generate_code',
      page: PAGE.PROJECT_CODE,
    });
    zipFileCreateMutation.mutate(data, {
      onSuccess: () => {
        track(EVENTS.SUCCESS, {
          page: PAGE.PROJECT_CODE,
          action: 'generate_code',
          technology: data.technologyValue,
        });
        refetchZipFiles();
        refetchMyAccount();
      },
      onError: () => {
        track(EVENTS.ERROR, {
          page: PAGE.PROJECT_CODE,
          action: 'generate_code',
          technology: data.technologyValue,
        });
      },
    });
  };

  const handleEditSchema = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'edit_schema',
      page: PAGE.PROJECT_CODE,
    });
    navigate(`/${projectId}`);
  };

  const handleFeedback = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'feedback',
      page: PAGE.PROJECT_CODE,
    });
    navigate(`/feedback/${projectId}`);
  };

  useEffect(() => {
    page({
      title: PAGE.PROJECT_CODE,
    });
  }, []);

  const renderIcon = (status: number, filePath?: string) => {
    if (status === 0) {
      return (
        <Box sx={{ mx: 1.5 }}>
          <CircularProgress size={22} />
        </Box>
      );
    } else if (status === 1 && filePath) {
      return (
        <IconButton
          LinkComponent={'a'}
          href={`${config.BASE_URL}/download/${filePath}`}
          onClick={() =>
            track(EVENTS.CLICK, {
              category: CATEGORY.ICON_BUTTON,
              label: 'icon_download',
              page: PAGE.PROJECT_CODE,
            })
          }
          download
        >
          <Icon iconName="download" tooltip="Download" />
        </IconButton>
      );
    } else if (status === -1) {
      return (
        <IconButton>
          <Icon iconName="error" tooltip="Something went wrong" />
        </IconButton>
      );
    }
    return null;
  };

  if (isFetching && !isRefetching) {
    return <PageLoader />;
  }

  if (project) {
    return (
      <>
        {zipFileCreateMutation.error ? (
          <Box mt={4}>
            <Alert
              error={zipFileCreateMutation.error}
              isError={zipFileCreateMutation.isError}
              action={
                <MuiLink
                  component={Link}
                  to={'https://code99.io'}
                  sx={{
                    color: 'black',
                    fontWeight: '600',
                    textDecoration: 'none',
                    mr: 1,
                    backgroundColor: (theme) => theme.palette.error.main,
                    py: 1,
                    px: 2,
                    borderRadius: 1,
                  }}
                >
                  Purchase
                </MuiLink>
              }
            />
          </Box>
        ) : (
          <SubscriptionAlert />
        )}
        <PageContainer
          title={project.name}
          rightComponent={() => (
            <Stack spacing={2} direction={'row'} alignItems={'center'}>
              <Box whiteSpace={'nowrap'}>
                <Button onClick={handleFeedback}>Share Feedback</Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={
                    <Icon iconName="schema" color="white" fill={false} />
                  }
                  onClick={handleEditSchema}
                >
                  Edit Schema
                </Button>
              </Box>
            </Stack>
          )}
        >
          <Paper sx={{ p: 4, mb: 4 }} variant="outlined">
            <Typography
              variant="subtitle2"
              mb={2}
              fontWeight={'bold'}
              letterSpacing={1}
            >
              DESCRIPTION
            </Typography>
            <Typography
              whiteSpace={'pre-wrap'}
              variant="body2"
              sx={s.styledForDescription}
            >
              {project.description}
            </Typography>
          </Paper>
          {project && project.id && project.status === 1 ? (
            <GenerateCodeForm
              defaultValues={{
                projectId: project.id,
                technologyId: '-1',
                technologyValue: '',
              }}
              onSubmit={handleGenerateCode}
              credit={myAccount?.cgCredit}
            />
          ) : (
            <div />
          )}
          {zipFiles?.result && zipFiles.result.length > 0 ? (
            <>
              <Paper variant="outlined">
                <Typography sx={{ p: 4 }}>
                  Note: Kindly refer to the README.md for comprehensive setup
                  instructions.
                </Typography>
                <List sx={{ pb: 0 }}>
                  {zipFiles.result.map((x, i) => (
                    <React.Fragment key={`${i}`}>
                      <ListItem
                        sx={{ px: 4, py: 2 }}
                        secondaryAction={
                          <Box sx={{ mx: 4 }}>
                            {renderIcon(x.status, x.filePath)}
                          </Box>
                        }
                      >
                        <ListItemText
                          primary={x.filePath || x.technology.name}
                        />
                      </ListItem>
                      {i !== zipFiles.result.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </>
          ) : (
            <div />
          )}
        </PageContainer>
      </>
    );
  }

  return null;
};

export default ProjectCode;
