import { SxProps } from '@mui/material';

const styledForDescription: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '10',
  WebkitBoxOrient: 'vertical',
};

export { styledForDescription };
