import {
  Button,
  Link as MuiLink,
  Stack,
  TextField,
  Typography,
  Alert as MuiAlert,
} from '@mui/material';
import * as s from './styles';
import { Controller, useForm } from 'react-hook-form';
import { LoginType } from '../../types';
import {
  useGoogleLogin,
  useLogin,
  useResendVerificationEmail,
} from '../../queries';
import { appRoutes } from '../../constants';
import { Link, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Alert } from '../../components';
import { useEffect, useState } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const LoginSchema = yup
  .object<LoginType>()
  .shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const Login = () => {
  const { page, track } = useAnalytics();
  const { search } = useLocation();
  const customerEmail = new URLSearchParams(search).get('customer_email') || '';

  const defaultValues = {
    email: '',
    password: '',
  };

  const loginMutation = useLogin();
  const googleLoginMutation = useGoogleLogin();
  const resendVerificationEmailMutation = useResendVerificationEmail();

  const [email, setEmail] = useState<string>();
  const [isResendEmailSent, setResendEmailSent] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>({
    defaultValues,
    resolver: yupResolver(LoginSchema),
  });

  const onLogin = (data: LoginType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'login',
      page: PAGE.LOGIN,
    });

    loginMutation.mutate(data, {
      onSuccess: () => {
        track(EVENTS.SUCCESS, {
          page: PAGE.LOGIN,
          action: 'login',
        });
      },
      onError: () => {
        track(EVENTS.ERROR, {
          page: PAGE.LOGIN,
          action: 'login',
        });
      },
    });
    setEmail(data.email);
    setResendEmailSent(false);
  };

  const handleResend = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'resend_email',
      page: PAGE.LOGIN,
    });

    if (email) {
      resendVerificationEmailMutation.mutate(
        { email },
        {
          onSuccess: () => {
            track(EVENTS.SUCCESS, {
              page: PAGE.LOGIN,
              action: 'resend_email',
            });
          },
          onError: () => {
            track(EVENTS.ERROR, {
              page: PAGE.LOGIN,
              action: 'resend_email',
            });
          },
        }
      );
      setResendEmailSent(true);
    }
  };

  const handleGoogleLogin = (credentialResponse: CredentialResponse) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'google_login',
      page: PAGE.LOGIN,
    });

    googleLoginMutation.mutate({ credential: credentialResponse.credential });
  };

  const onGoogleLoginError = () => {
    track(EVENTS.ERROR, {
      page: PAGE.LOGIN,
      action: 'google_login',
    });
  };

  useEffect(() => {
    page({
      title: PAGE.LOGIN,
    });
  }, []);

  return (
    <s.Container>
      <Typography variant="h4">Login</Typography>
      {customerEmail && (
        <MuiAlert severity="info">
          {customerEmail} is already subscribe
        </MuiAlert>
      )}
      <s.FormWrapper component="form" onSubmit={handleSubmit(onLogin)}>
        <Controller
          control={control}
          name="email"
          defaultValue={defaultValues.email}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label="Email"
              error={!!errors.email?.message}
              helperText={errors.email?.message}
              autoFocus
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          defaultValue={defaultValues.password}
          render={({ field }) => (
            <TextField
              {...field}
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              error={!!errors.password?.message}
              helperText={errors.password?.message}
            />
          )}
        />
        <Alert
          isError={resendVerificationEmailMutation.isError}
          isSuccess={resendVerificationEmailMutation.isSuccess}
          error={resendVerificationEmailMutation.error}
          successMessage="Email resend successfully"
        />
        {!isResendEmailSent && (
          <Alert
            isError={loginMutation.isError}
            isSuccess={loginMutation.isSuccess}
            error={loginMutation.error}
            action={
              loginMutation.error?.errorCode === 'EMAIL_NOT_VERIFIED' ? (
                <Button color="inherit" size="small" onClick={handleResend}>
                  RESEND EMAIL
                </Button>
              ) : undefined
            }
          />
        )}
        <Stack spacing={2} mb={2} alignItems={'center'}>
          <s.LoginButton
            type="submit"
            fullWidth
            variant="contained"
            size="large"
          >
            Login
          </s.LoginButton>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={onGoogleLoginError}
            theme="filled_black"
            shape="circle"
          />
        </Stack>
        <Stack
          direction={{
            sm: 'column',
            md: 'row',
          }}
          justifyContent={'space-between'}
        >
          <MuiLink
            component={Link}
            to={appRoutes.FORGOT_PASSWORD}
            underline="none"
            onClick={() => {
              track(EVENTS.CLICK, {
                category: CATEGORY.LINK,
                label: 'forgot_password',
                page: PAGE.LOGIN,
              });
            }}
          >
            Forgot password?
          </MuiLink>
          <MuiLink
            component={Link}
            to={appRoutes.SIGN_UP}
            underline="none"
            onClick={() => {
              track(EVENTS.CLICK, {
                category: CATEGORY.LINK,
                label: 'register',
                page: PAGE.LOGIN,
              });
            }}
          >
            {"Don't have an account? Register"}
          </MuiLink>
        </Stack>
      </s.FormWrapper>
    </s.Container>
  );
};

export default Login;
