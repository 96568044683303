import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProjectDetailsModal } from '../../components';
import { useCreateProject } from '../../queries';
import { CreateProjectType } from '../../types';
import { appRoutes } from '../../constants';
import { useAnalytics } from 'use-analytics';
import { useEffect } from 'react';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';
import { base64ToObject } from '../../utils/common';

const NewProject = () => {
  const { page, track } = useAnalytics();

  const [searchParams] = useSearchParams();
  const { description } = base64ToObject(searchParams.get('payload') || '');

  const navigate = useNavigate();
  const createProjectMutation = useCreateProject();

  const handleSaveProject = (data: CreateProjectType) => {
    createProjectMutation.mutate(data, {
      onSuccess: (data) => {
        track(EVENTS.SUCCESS, {
          page: PAGE.NEW_PROJECT,
          action: 'create_project',
        });
        if (data?.id) {
          navigate(`/${data.id}`, { replace: true });
        }
      },
      onError: () => {
        track(EVENTS.ERROR, {
          page: PAGE.NEW_PROJECT,
          action: 'create_project',
        });
      },
    });
  };

  const handleCancel = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'cancel',
      page: PAGE.NEW_PROJECT,
    });
    navigate(appRoutes.HOME);
  };

  useEffect(() => {
    page({
      title: PAGE.NEW_PROJECT,
    });
  }, []);

  return (
    <ProjectDetailsModal
      open={true}
      onSubmit={handleSaveProject}
      onCancel={handleCancel}
      pageName={PAGE.NEW_PROJECT}
      defaultValues={{ name: '', description }}
    />
  );
};

export default NewProject;
