import googleTagManager from '@analytics/google-tag-manager';
import postHog from '@metro-fs/analytics-plugin-posthog';
import { Analytics } from 'analytics';

const postHogInstance = postHog({
  token: import.meta.env.VITE_POSTHOG_KEY,
  enabled: true,
  options: {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    debug: import.meta.env.DEV,
    persistence: 'localStorage',
    disable_session_recording: false,
  },
});

const gtmInstance = googleTagManager({
  containerId: import.meta.env.VITE_GTM_ID,
});

const analytics = Analytics({
  app: 'code99-frontend',
  debug: import.meta.env.DEV,
  plugins: [postHogInstance, gtmInstance],
});

export default analytics;
