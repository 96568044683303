import React, { memo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
} from '@mui/material';

import * as s from './styles';
import DBTableListRow from '../DBTableListRow';
import Icon from '../../Icon';
import { blueGrey } from '@mui/material/colors';
import { indigo } from '../../../theme/colors';

export interface DBTableListProps {
  tables: {
    id: string;
    name: string;
    canNotDelete?: boolean;
  }[];
  onRowClick: (id: string) => void;
  onAddNewTable: () => void;
  onDelete: (id: string) => void;
}

const DBTableList: React.FC<DBTableListProps> = ({
  tables,
  onRowClick,
  onAddNewTable,
  onDelete,
}) => {
  return (
    <Stack direction={'row'} alignItems={'flex-start'}>
      <s.Container>
        <Accordion sx={{ p: 0, bgcolor: blueGrey[900] }}>
          <AccordionSummary
            expandIcon={<Icon iconName="expand_more" size={20} />}
          >
            <s.Header direction={'row'} alignItems={'center'}>
              <Typography fontSize={16} fontWeight={'bold'}>
                Tables
              </Typography>
            </s.Header>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, maxHeight: 420, overflowY: 'scroll' }}>
            <s.Content>
              {tables.map((table, index) => (
                <DBTableListRow
                  key={index}
                  tableName={table.name}
                  canNotDelete={table.canNotDelete}
                  onRowClick={() => onRowClick(table.id)}
                  onDelete={() => onDelete(table.id)}
                />
              ))}
            </s.Content>
          </AccordionDetails>
        </Accordion>
      </s.Container>
      <s.Button
        size="small"
        variant="outlined"
        onClick={onAddNewTable}
        sx={{ color: indigo.light, borderColor: indigo.light }}
      >
        ADD TABLE
      </s.Button>
    </Stack>
  );
};

export default memo(DBTableList);
