import React from 'react';
import {
  Autocomplete,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CreateZipFileType, TechnologyType } from '../../types';
import { useTechnologyDropdown } from '../../queries';

export interface GenerateCodeFormProps {
  defaultValues: CreateZipFileType;
  onSubmit: (data: CreateZipFileType) => void;
  credit?: number;
}

const GenerateCodeSchema = yup.object<CreateZipFileType>().shape({
  projectId: yup.string().required('Project id is required'),
  technologyId: yup
    .string()
    .test(
      'not-equal-to-negative-one',
      'Please select a technology',
      (value) => value !== '-1'
    )
    .required('Technology is required'),
  technologyValue: yup.string().required(),
});

const GenerateCodeForm: React.FC<GenerateCodeFormProps> = ({
  defaultValues,
  onSubmit,
  credit,
}) => {
  const { data: technologies } = useTechnologyDropdown();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<CreateZipFileType>({
    defaultValues,
    resolver: yupResolver(GenerateCodeSchema),
  });

  return (
    <Paper
      sx={{ p: 4 }}
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      variant="outlined"
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Autocomplete
          disablePortal
          options={technologies || []}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label="Technologies"
                error={!!errors.technologyId?.message}
                helperText={errors.technologyId?.message}
              />
            </>
          )}
          getOptionLabel={(option) => option.name}
          onChange={(event: any, newValue: TechnologyType | null) => {
            if (newValue === null) {
              setValue('technologyId', '-1'); // Set the value to an empty string if unselected
              setValue('technologyValue', '');
            } else {
              setValue('technologyId', newValue.id);
              setValue('technologyValue', newValue.value);
            }
            trigger('technologyId');
            trigger('technologyValue');
          }}
        />

        <Stack direction={'column'} alignItems={'center'} spacing={1}>
          <Button
            variant="contained"
            type="submit"
            sx={{ alignSelf: 'center' }}
          >
            Generate Code
          </Button>
          {/* TODO: Enable this later 
          <Typography variant="caption">{`${
            credit === -10 ? 'Unlimited credit' : credit + ' Credit Remaining'
          } `}</Typography> */}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(GenerateCodeForm);
