import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FeedbackType } from '../../types';

export interface FeedbackFormProps {
  defaultValues: FeedbackType;
  onSubmit: (data: FeedbackType) => void;
}

const FeedbackSchema = yup.object<FeedbackType>().shape({
  projectId: yup.string().required('Project id is required'),
  message: yup.string().required('Feedback message is required'),
});

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FeedbackType>({
    defaultValues,
    resolver: yupResolver(FeedbackSchema),
  });

  const handleFormSubmit = (data: FeedbackType) => {
    onSubmit(data);
    reset();
  };

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Card>
        <CardHeader
          subheader="Help Us Improve Your Experience – We Value Your Input!"
          title="Share Your Thoughts"
        />
        <Divider />
        <CardContent>
          <Box>
            <Controller
              name="message"
              control={control}
              defaultValue={defaultValues.message || ''}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Feedback message"
                  {...field}
                  error={!!errors.message?.message}
                  helperText={errors.message?.message}
                />
              )}
            />
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button variant="contained" type="submit">
            Send Feedback
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default FeedbackForm;
