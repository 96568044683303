import { Box, Container, Stack, Typography } from '@mui/material';
import {
  AccountProfileForm,
  Alert,
  ChangePasswordForm,
  CircularProgress,
} from '../../components';
import {
  useChangePassword,
  useMyAccount,
  useUpdateMyAccount,
} from '../../queries';
import { ChangePasswordType, MyAccountType } from '../../types';
import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const MyAccount = () => {
  const { page, track } = useAnalytics();

  const { isLoading, data: defaultValues } = useMyAccount(false);
  const updateMyAccountMutation = useUpdateMyAccount();
  const changePasswordMutation = useChangePassword();

  const handleSubmit = (data: MyAccountType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'update_my_account',
      page: PAGE.MY_ACCOUNT,
    });
    updateMyAccountMutation.mutate(data, {
      onSuccess: () => {
        track(EVENTS.SUCCESS, {
          page: PAGE.MY_ACCOUNT,
          action: 'update_my_account',
        });
      },
      onError: () => {
        track(EVENTS.ERROR, {
          page: PAGE.MY_ACCOUNT,
          action: 'update_my_account',
        });
      },
    });
  };

  const handleChangePassword = (data: ChangePasswordType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'change_password',
      page: PAGE.MY_ACCOUNT,
    });
    changePasswordMutation.mutate(data, {
      onSuccess: () => {
        track(EVENTS.SUCCESS, {
          page: PAGE.MY_ACCOUNT,
          action: 'change_password',
        });
      },
      onError: () => {
        track(EVENTS.ERROR, {
          page: PAGE.MY_ACCOUNT,
          action: 'change_password',
        });
      },
    });
  };

  useEffect(() => {
    page({
      title: PAGE.MY_ACCOUNT,
    });
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Account</Typography>
          </div>
          <Stack spacing={3}>
            {isLoading ? (
              <CircularProgress />
            ) : defaultValues ? (
              <>
                <Alert
                  isError={updateMyAccountMutation.isError}
                  isSuccess={updateMyAccountMutation.isSuccess}
                  error={updateMyAccountMutation.error}
                  successMessage="Account info update successfully"
                />
                <Alert
                  isError={changePasswordMutation.isError}
                  isSuccess={changePasswordMutation.isSuccess}
                  error={changePasswordMutation.error}
                  successMessage="Password Changed successfully"
                />
                <AccountProfileForm
                  defaultValues={defaultValues}
                  onSubmit={handleSubmit}
                />
                {!defaultValues.provider && (
                  <ChangePasswordForm
                    onSubmit={handleChangePassword}
                    isLoading={changePasswordMutation.isLoading}
                  />
                )}
              </>
            ) : null}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default MyAccount;
