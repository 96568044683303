import { Box, styled } from '@mui/material';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    min-height: 100%;
    margin: 0px;
  }
`;

const Container = styled(Box)`
  display: flex;
  flex: 1;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export { GlobalStyle, Container };
