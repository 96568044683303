import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import {
  AISchemaModal,
  LoaderWithModal,
  MainDiagram,
  PageLoader,
  ProjectDetailsModal,
} from '../../components';

import {
  useGenerateProjectSchema,
  useProjectDetails,
  useUpdateProjectDetails,
} from '../../queries';
import {
  CreateProjectType,
  GenerateProjectSchemaType,
  JsonDBType,
} from '../../types';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';
import { useAnalytics } from 'use-analytics';

const ProjectDetails = () => {
  const { page, track } = useAnalytics();
  const navigate = useNavigate();

  // React Query
  const { projectId: _id } = useParams();

  const projectId = _id || '';

  const {
    data: project,
    refetch: refetchProjectDetails,
    isFetching,
    isRefetching,
  } = useProjectDetails(projectId);

  const updateProject = useUpdateProjectDetails(projectId);
  const generateProjectSchema = useGenerateProjectSchema(projectId);

  const [openDetailForm, setOpenDetailForm] = useState<boolean>(false);
  const [openGenerateSchemaForm, setOpenGenerateSchemaForm] =
    useState<boolean>(false);

  const handleEditProjectDetails = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'edit_project_details',
      page: PAGE.PROJECT_DETAILS,
    });
    setOpenDetailForm(true);
  };

  const handleSaveSchema = (sqlSchema: JsonDBType, goToCode?: boolean) => {
    updateProject.mutate(
      {
        sqlSchema,
        status: 1,
      },
      {
        onSuccess: () => {
          if (goToCode) {
            track(EVENTS.SUCCESS, {
              page: PAGE.PROJECT_DETAILS,
              action: 'generate_code',
            });
            navigate(`/${projectId}/code`);
          } else {
            track(EVENTS.SUCCESS, {
              page: PAGE.PROJECT_DETAILS,
              action: 'save_schema',
            });
            refetchProjectDetails();
          }
        },
        onError: () => {
          if (goToCode) {
            track(EVENTS.ERROR, {
              page: PAGE.PROJECT_DETAILS,
              action: 'generate_code',
            });
          } else {
            track(EVENTS.ERROR, {
              page: PAGE.PROJECT_DETAILS,
              action: 'save_schema',
            });
          }
        },
      }
    );
  };

  const handleCloseProjectDetails = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'close_project_details',
      page: PAGE.PROJECT_DETAILS,
    });
    setOpenDetailForm(false);
  };

  const handleSaveProjectDetails = (data: CreateProjectType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'update_project',
      page: PAGE.PROJECT_DETAILS,
    });
    updateProject.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          track(EVENTS.SUCCESS, {
            page: PAGE.PROJECT_DETAILS,
            action: 'update_project',
          });
          refetchProjectDetails();
          handleCloseProjectDetails();
        },
        onError: () => {
          track(EVENTS.ERROR, {
            page: PAGE.PROJECT_DETAILS,
            action: 'update_project',
          });
        },
      }
    );
  };

  const handleOpenGenerateSchemaForm = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'generate_schema',
      page: PAGE.PROJECT_DETAILS,
    });
    setOpenGenerateSchemaForm(true);
  };

  const handleCloseGenerateSchemaForm = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'close_generate_schema',
      page: PAGE.PROJECT_DETAILS,
    });
    setOpenGenerateSchemaForm(false);
  };

  const handleGenerateSchema = (data: GenerateProjectSchemaType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'generate_schema',
      page: PAGE.PROJECT_DETAILS,
    });
    generateProjectSchema.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          track(EVENTS.SUCCESS, {
            page: PAGE.PROJECT_DETAILS,
            action: 'generate_schema',
          });
          refetchProjectDetails();
          handleCloseGenerateSchemaForm();
        },
        onError: () => {
          track(EVENTS.ERROR, {
            page: PAGE.PROJECT_DETAILS,
            action: 'generate_schema',
          });
        },
      }
    );
  };

  useEffect(() => {
    page({
      title: PAGE.PROJECT_DETAILS,
    });
  }, []);

  if (isFetching && !isRefetching) {
    return <PageLoader />;
  }

  if (project) {
    return (
      <>
        <ReactFlowProvider>
          <MainDiagram
            project={project}
            onSaveChanges={handleSaveSchema}
            onEditProjectDetails={handleEditProjectDetails}
            onGenerateSchema={handleOpenGenerateSchemaForm}
            pageName={PAGE.PROJECT_DETAILS}
          />
        </ReactFlowProvider>
        {(openDetailForm || updateProject.error) && (
          <ProjectDetailsModal
            open={openDetailForm}
            defaultValues={{
              name: project.name,
              description: project.description,
            }}
            onSubmit={handleSaveProjectDetails}
            onCancel={handleCloseProjectDetails}
            error={updateProject.error}
            pageName={PAGE.PROJECT_DETAILS}
            isEdit={true}
          />
        )}
        {(openGenerateSchemaForm || generateProjectSchema.error) && (
          <AISchemaModal
            open={openGenerateSchemaForm}
            defaultValues={{ description: project.description }}
            onCancel={handleCloseGenerateSchemaForm}
            onSubmit={handleGenerateSchema}
            error={generateProjectSchema.error}
            pageName={PAGE.PROJECT_DETAILS}
          />
        )}
        {(project.status === 2 || project.status === 0) && (
          <LoaderWithModal
            text="Please Wait, Your database schema is generating by AI"
            open={true}
          />
        )}
      </>
    );
  }

  return null;
};

export default ProjectDetails;
