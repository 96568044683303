import { Box, Button, Stack, Typography } from '@mui/material';

export interface PageContainerProps {
  title: string;
  rightComponent?: () => React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
}

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  rightComponent,
  children,
}) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 4,
      }}
    >
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
          px={2}
        >
          <Stack spacing={1} direction={'row'} alignItems={'center'}>
            <Typography variant="h4">{title}</Typography>
          </Stack>
          {rightComponent && rightComponent()}
        </Stack>
        {children}
      </Stack>
    </Box>
  );
};

export default PageContainer;
