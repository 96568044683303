import {
  Box,
  Button,
  TextField,
  Unstable_Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { GenerateProjectSchemaType, ErrorType } from '../../types';
import ModalWithTitle from '../ModalWithTitle';
import Alert from '../Alert';
import Icon from '../Icon';
import { info } from '../../theme/colors';

export interface AISchemaModalProps {
  open: boolean;
  defaultValues?: GenerateProjectSchemaType;
  onSubmit: (data: GenerateProjectSchemaType) => void;
  onCancel: () => void;
  error?: ErrorType;
  pageName: string;
}

const AISchema = yup.object<GenerateProjectSchemaType>().shape({
  description: yup
    .string()
    .min(30, 'Project description has at least 30 characters')
    .max(1500, 'Project Description must be at most 1500 characters')
    .required('Project Description is required'),
});

const AISchemaModal: React.FC<AISchemaModalProps> = ({
  open,
  defaultValues,
  onSubmit,
  onCancel,
  error,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<GenerateProjectSchemaType>({
    defaultValues,
    resolver: yupResolver(AISchema),
  });

  return (
    <ModalWithTitle
      open={open}
      title={'Generate Schema Using AI'}
      subTitle={'Generate database schema from your project description'}
      form={{
        onSubmit: handleSubmit(onSubmit),
      }}
      renderAction={() => (
        <Stack spacing={2} width={'100%'}>
          {error && <Alert error={error} isError />}
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'}>
            <Button onClick={onCancel} size="small" color="success">
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              color="success"
              startIcon={<Icon iconName="bolt" size={24} color="black" />}
            >
              Generate Schema
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Box width={560}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue={defaultValues?.description || ''}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  placeholder="Ex. Create a feature-rich blogging platform that allows users to register accounts, write and publish blog posts, manage drafts, categorize posts with tags, enable comments and likes on posts, provide user profiles, implement a search functionality, and support rich media embedding for an engaging and interactive blogging experience."
                  {...field}
                  error={!!errors.description?.message}
                  helperText={
                    errors.description?.message ||
                    `${getValues('description').length}/1500`
                  }
                  inputProps={{
                    style: {
                      height: 200,
                      overflow: 'scroll',
                    },
                  }}
                  multiline
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Typography fontSize={14} fontStyle={'italic'} color={info.main}>
              Note: Your current database schema will be erased
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ModalWithTitle>
  );
};

export default AISchemaModal;
