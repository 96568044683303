import * as yup from 'yup';
import {
  CreateProjectType,
  JsonDBReferenceColumnType,
  JsonDBTableColumnType,
  JsonDBTableType,
} from '../types';
import { Node } from 'reactflow';

export const ProjectDetailSchema = (isGenerateAISchema: boolean) => {
  return yup.object<CreateProjectType>({
    name: yup
      .string()
      .max(50, 'Project Name must be at most 50 characters')
      .required('Project Name is required'),
    description: isGenerateAISchema
      ? yup
          .string()
          .min(30, 'Project description has at least 30 characters')
          .max(1500, 'Project Description must be at most 1500 characters')
          .required('Project Description is required')
      : yup.string(),
  });
};

const ColumnTypeSchema = yup
  .object<JsonDBTableColumnType>()
  .shape({
    name: yup.string().required('Column Name is required'),
    type: yup.string().required('Column Type is required'),
    primary: yup.boolean().optional(),
    index: yup.boolean().optional(),
    required: yup.boolean().optional(),
    fk: yup
      .object<JsonDBReferenceColumnType>()
      .shape({
        type: yup.string().required(),
        table: yup.string().required(),
        refCol: yup.string().required(),
        labelCol: yup.string().required(),
      })
      .optional()
      .default(undefined),
    defaultType: yup
      .object()
      .shape({
        value: yup
          .mixed<string | number | boolean>()
          .required()
          .test('is-valid-value', 'Invalid value', (value) => {
            // Check if the value is a string, number, or boolean
            return (
              typeof value === 'string' ||
              typeof value === 'number' ||
              typeof value === 'boolean'
            );
          }),
        note: yup.string().optional(),
      })
      .optional()
      .default(undefined),
  })
  .test(
    'unique-name-error',
    'Column names must be unique',
    function (value, { parent }) {
      // Use a Set to keep track of unique names

      const columns = parent.filter((col: any) => {
        // console.log('Name > ', col.name, value.name);
        return col.name === value.name;
      });

      return !(columns.length > 1);
    }
  );

export const getSchemaForEditTable = (nodes: Node<JsonDBTableType>[]) =>
  yup.object<JsonDBTableType>().shape({
    name: yup
      .string()
      .test('table-unique', 'Same table name already exists', function (value) {
        const existingTables = nodes.find((x) => x.data.name === value);

        // Check if the table name already exists among existing tables
        return !existingTables;
      })
      .required('Name is required'),
    cols: yup.array<JsonDBTableColumnType>().of(ColumnTypeSchema).required(),
    canNotDelete: yup.bool().optional(),
  });
