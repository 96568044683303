import { JsonDBTableType, JsonDBType } from '../types';

export const cleanTableKeys = (data: JsonDBTableType) => {
  return {
    ...data,
    cols: data.cols.map((col) => {
      if (col.fk && Object.keys(col.fk).length === 0) {
        delete col.fk;
      }
      if (!col.primary) {
        delete col.primary;
      }
      if (!col.index) {
        delete col.index;
      }
      return col;
    }),
  };
};

export const updateRefTableName = (
  currentTableName: string,
  newTableName: string,
  dbSchema: JsonDBType
): JsonDBType => {
  return {
    tables: [
      ...dbSchema.tables.map((table) => {
        return {
          ...table,
          cols: table.cols.map((col) => {
            if (col.fk?.table === currentTableName) {
              col.fk.table = newTableName;
            }
            return col;
          }),
        };
      }),
    ],
  };
};

export const removeRefOfTable = (
  tableName: string,
  dbSchema: JsonDBType
): JsonDBType => {
  return {
    tables: [
      ...dbSchema.tables.map((table) => {
        return {
          ...table,
          cols: table.cols.map((col) => {
            if (col.fk?.table === tableName) {
              delete col.fk;
            }
            return col;
          }),
        };
      }),
    ],
  };
};
