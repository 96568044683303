import { Stack, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const Container = styled(Stack)`
  padding: 10px 12px;
  border-top: 0.25px solid ${blueGrey[400]};
  justify-content: space-between;
  position: relative;
  background-color: ${blueGrey[800]};
`;

export { Container };
