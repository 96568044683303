import { Edge, Node, NodeChange, applyNodeChanges } from 'reactflow';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { getUpdatedEdges, removeRefEdges } from '../utils';
import { JsonDBTableType } from '../types';
import { NEW_TABLE_COLUMNS } from '../constants';

interface State<T> {
  nodes: Node<T>[];
  edges: Edge[];
  selectedNodeId?: string;
}

interface Actions<T> {
  setNodes: (nodes: Node<T>[]) => void;
  setEdges: (edges: Edge[]) => void;
  onNodesChange: (changes: NodeChange[]) => void;
  addNewNode: (onNodeAdded?: (node: Node<T>) => void) => void;
  setSelectedNode: (id: string) => void;
  getSelectedNode: () => Node<T> | undefined;
  clearSelectedNode: () => void;
  updateSelectedNode: (updatedTable: JsonDBTableType) => void;
  deleteSelectedNode: () => void;
  deleteNodeById: (id: string) => void;
}

export const useDiagramSlice = create(
  immer<State<JsonDBTableType> & Actions<JsonDBTableType>>((set, get) => ({
    nodes: [],
    edges: [],
    dbSchema: { tables: [] },
    selectedTableIndex: undefined,
    setNodes: (nodes: Node[]) =>
      set((state) => {
        state.nodes = nodes;
      }),
    setEdges: (edges: Edge[]) =>
      set((state) => {
        state.edges = edges;
      }),
    onNodesChange: (changes: NodeChange[]) =>
      set((state) => {
        state.nodes = applyNodeChanges(changes, state.nodes);
      }),
    addNewNode: (onNodeAdded) =>
      set((state) => {
        const index = state.nodes.length;
        const newNode: Node<JsonDBTableType> = {
          id: `${index}`,
          type: 'dbTable',
          data: {
            name: `newTable_${index}`,
            cols: NEW_TABLE_COLUMNS,
          },
          position: {
            x: 250 * (index * 1.5),
            y: 250 * (1 * (index % 2 === 0 ? 1 : -1)),
          },
        };
        state.nodes.push(newNode);
        if (onNodeAdded) {
          onNodeAdded(newNode);
        }
      }),
    setSelectedNode: (id: string) =>
      set((state) => {
        state.selectedNodeId = id;
      }),
    getSelectedNode: () => {
      const state = get();
      if (state.selectedNodeId !== undefined) {
        return state.nodes.find((node) => node.id === state.selectedNodeId);
      }
      return undefined;
    },
    clearSelectedNode: () =>
      set((state) => {
        state.selectedNodeId = undefined;
      }),
    updateSelectedNode: (updatedTable: JsonDBTableType) => {
      set((state) => {
        if (state.selectedNodeId !== undefined) {
          const nodeIndex = state.nodes.findIndex(
            (x) => x.id === state.selectedNodeId
          );
          state.edges = removeRefEdges(
            state.selectedNodeId,
            state.edges,
            'target'
          );

          state.nodes[nodeIndex].data = updatedTable;

          const updatedEdges = getUpdatedEdges(state.nodes, updatedTable);

          state.edges = state.edges.concat(updatedEdges);

          state.selectedNodeId = undefined;
        }
      });
    },
    deleteSelectedNode: () =>
      set((state) => {
        if (state.selectedNodeId !== undefined) {
          state.nodes = state.nodes.filter(
            (node) => node.id !== state.selectedNodeId
          );
          state.edges = removeRefEdges(state.selectedNodeId, state.edges);
          state.selectedNodeId = undefined;
        }
      }),

    deleteNodeById: (id: string) =>
      set((state) => {
        state.nodes = state.nodes.filter((node) => node.id !== id);
        state.edges = removeRefEdges(id, state.edges);
      }),
  }))
);
