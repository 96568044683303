import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  Background,
  Controls,
  Node,
  NodeTypes,
  Panel,
  ReactFlow,
  useReactFlow,
  useUpdateNodeInternals,
} from 'reactflow';
import 'reactflow/dist/style.css';

import DBTableNode from '../DBTableNode';
import { JsonDBTableType, JsonDBType, ProjectType } from '../../../types';
import { useDiagramSlice } from '../../../store';
import { defaultEdgeOptions, getNodesAndEdgesFromJsonDB } from '../../../utils';
import DBTableList from '../DBTableList';
import DBEditTableModal from '../DBEditTableModal';
import Icon from '../../Icon';
import { indigo } from '../../../theme/colors';
import { useUpdateEffect } from '../../../hook';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS } from '../../../constants/analyticsEvent';

const nodeTypes: NodeTypes = { dbTable: DBTableNode };

interface MainDiagramProps {
  project: ProjectType;
  onGenerateSchema: () => void;
  onEditProjectDetails: () => void;
  onSaveChanges: (sqlSchema: JsonDBType, goToCode?: boolean) => void;
  pageName: string;
}

const MainDiagram: React.FC<MainDiagramProps> = ({
  project,
  onGenerateSchema,
  onEditProjectDetails,
  onSaveChanges,
  pageName,
}) => {
  const { track } = useAnalytics();

  // Reactflow hooks
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  // Local Variables
  const isInternalTableUpdated = useRef(false);

  // States from Diagram Slice
  const nodes = useDiagramSlice((state) => state.nodes);
  const edges = useDiagramSlice((state) => state.edges);
  const selectedNodeId = useDiagramSlice((state) => state.selectedNodeId);

  // Actions from Diagram Slice
  const setNodes = useDiagramSlice((state) => state.setNodes);
  const setEdges = useDiagramSlice((state) => state.setEdges);

  const updateSelectedNode = useDiagramSlice(
    (state) => state.updateSelectedNode
  );
  const deleteSelectedNode = useDiagramSlice(
    (state) => state.deleteSelectedNode
  );
  const clearSelectedNode = useDiagramSlice((state) => state.clearSelectedNode);
  const onNodesChange = useDiagramSlice((state) => state.onNodesChange);
  const addNewNode = useDiagramSlice((state) => state.addNewNode);
  const deleteNodeById = useDiagramSlice((state) => state.deleteNodeById);

  // Transform table data for table list.
  const tables = useMemo(() => {
    return nodes.map((x) => {
      return {
        id: x.id,
        name: x.data.name,
        canNotDelete: x.data.canNotDelete,
      };
    });
  }, [nodes.length, isInternalTableUpdated.current]);

  // Viewport operations
  const handleNodeFocus = useCallback(
    (node: Node) => {
      // Use the fitView method to focus on the specific node's position
      reactFlowInstance.fitView({
        nodes: [node],
        maxZoom: 1,
      });
    },
    [nodes.length]
  );

  const handleNodeFocusById = useCallback(
    (id: string) => {
      if (reactFlowInstance) {
        const node = nodes.find((n) => n.id === id);

        if (node) {
          // Use the fitView method to focus on the specific node's position
          handleNodeFocus(node);
        }
      }
    },
    [nodes.length]
  );

  // Table and Node Operations
  const handleSaveEditTableChanges = (updatedTable: JsonDBTableType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'save_table_changes',
      page: pageName,
    });

    updateSelectedNode(updatedTable);
    if (selectedNodeId) {
      updateNodeInternals(selectedNodeId);
      isInternalTableUpdated.current = true;
    }
  };

  const handleDeleteTable = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'delete_table',
      page: pageName,
    });
    deleteSelectedNode();
  };

  const handleDeleteTableById = useCallback(
    (id: string) => {
      deleteNodeById(id);
    },
    [nodes.length]
  );

  const handleAddNewTable = useCallback(() => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'add_new_table',
      page: pageName,
    });

    addNewNode((node) =>
      // TODO: Fix this later
      setTimeout(() => {
        handleNodeFocus(node);
      }, 100)
    );
  }, [nodes.length]);

  // Save Changes
  const handleSaveSchema = (goToCode?: boolean) => {
    const sqlSchema: JsonDBType = {
      tables: nodes.map((node) => {
        return node.data;
      }),
    };
    onSaveChanges(sqlSchema, goToCode);
  };

  // Effects
  useEffect(() => {
    if (project.sqlSchema) {
      const { tableNodes, relationEdges } = getNodesAndEdgesFromJsonDB(
        project.sqlSchema
      );
      setNodes(tableNodes);
      setEdges(relationEdges);
      const nodeIds = tableNodes.map((x) => x.id);
      updateNodeInternals(nodeIds);
    } else {
      setNodes([]);
      setEdges([]);
    }
  }, [project.sqlSchema]);

  useUpdateEffect(() => {
    if (isInternalTableUpdated.current) {
      handleSaveSchema();
      isInternalTableUpdated.current = false;
    }
  }, [nodes.length, isInternalTableUpdated.current]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <ReactFlow
        defaultEdgeOptions={defaultEdgeOptions}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        fitView
      >
        <Controls />
        <Background />
        <Panel position="top-left">
          <DBTableList
            tables={tables}
            onRowClick={handleNodeFocusById}
            onAddNewTable={handleAddNewTable}
            onDelete={handleDeleteTableById}
          />
        </Panel>
        <Panel position="top-center">
          <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <Stack
              direction={'row'}
              sx={{
                px: 3,
                py: 1,
                bgcolor: (theme) => theme.palette.background.paper,
                borderRadius: 50,
              }}
              spacing={2}
              alignItems={'center'}
            >
              <Typography fontSize={14} fontWeight={'600'}>
                {project.name}
              </Typography>
              <IconButton size="small" onClick={onEditProjectDetails}>
                <Icon
                  iconName="edit"
                  size={20}
                  color={indigo.lightest}
                  tooltip="Edit Project Details"
                />
              </IconButton>
            </Stack>
            <Button
              variant="contained"
              color="success"
              sx={{ borderRadius: 50 }}
              size={'small'}
              startIcon={
                <Icon
                  iconName="bolt"
                  size={24}
                  color={'black'}
                  tooltip="Generate Schema using AI"
                />
              }
              onClick={onGenerateSchema}
            >
              AI SCHEMA
            </Button>
          </Stack>
        </Panel>
        <Panel position="top-right">
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Button
              variant="contained"
              size="small"
              sx={{ borderRadius: 50 }}
              startIcon={<Icon iconName="code" />}
              onClick={() => handleSaveSchema(true)}
            >
              GENERATE CODE
            </Button>
          </Stack>
        </Panel>
      </ReactFlow>
      {!!selectedNodeId && (
        <DBEditTableModal
          open={!!selectedNodeId}
          onClose={clearSelectedNode}
          onSaveChanges={handleSaveEditTableChanges}
          onDeleteTable={handleDeleteTable}
          pageName={pageName}
        />
      )}
    </div>
  );
};

export default MainDiagram;
