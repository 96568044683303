import { Box } from '@mui/material';
import MuiCircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

export interface CircularProgressProps {
  size?: number;
  autoHeight?: boolean;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  size = 50,
  autoHeight,
}) => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      height={autoHeight ? 'auto' : '25vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box sx={{ position: 'relative', maxWidth: size }}>
        <MuiCircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={size}
          thickness={4}
          value={100}
        />
        <MuiCircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size}
          thickness={4}
        />
      </Box>
    </Box>
  );
};

export default CircularProgress;
