import {
  Button,
  Unstable_Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  CircularProgress,
  PageContainer,
  ProjectCard,
  SubscriptionAlert,
} from '../../components';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../constants';
import { useProjects } from '../../queries';
import { useAnalytics } from 'use-analytics';
import { useEffect } from 'react';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const Home = () => {
  const { page, track, user } = useAnalytics();

  console.log(user());

  useEffect(() => {
    page({
      title: PAGE.HOME,
    });
  }, []);

  const navigate = useNavigate();

  const { data, isFetching } = useProjects(0);

  const handleNewProject = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'new_project',
      page: PAGE.HOME,
    });
    navigate(appRoutes.NEW_PROJECT);
  };

  const handleProjectDetails = (projectId: string) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.CARD,
      label: 'project_details',
      page: PAGE.HOME,
    });
    navigate(`${projectId}`);
  };

  const handleProjectCode = (projectId: string) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      container: 'project_card',
      page: PAGE.HOME,
    });
    navigate(`${projectId}/code`);
  };

  const renderLoader = () => {
    if (isFetching) {
      return <CircularProgress />;
    }
    return <div />;
  };

  const renderData = () => {
    if (data?.result && data.result.length > 0) {
      return (
        <Grid container spacing={3}>
          {data.result.map((project) => (
            <Grid xs={12} md={6} lg={4} key={project.id}>
              <ProjectCard
                project={project}
                onClick={() => project.id && handleProjectDetails(project.id)}
                onCodeClick={() => project.id && handleProjectCode(project.id)}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    return <div />;
  };

  const renderEmpty = () => {
    if (data?.result && data.result.length < 1) {
      return (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
            whiteSpace: 'pre-wrap',
          }}
          spacing={1}
        >
          <Typography variant="h4" color={'grey'}>
            You do not have any project
          </Typography>
          <Typography variant="h5" color={'grey'}>
            Please create new project
          </Typography>
        </Stack>
      );
    }
    return <div />;
  };

  return (
    <>
      <SubscriptionAlert />
      <PageContainer
        title="Projects"
        rightComponent={() => (
          <div>
            <Button variant="contained" onClick={handleNewProject}>
              New Project
            </Button>
          </div>
        )}
      >
        {renderLoader()}
        {renderData()}
        {renderEmpty()}
      </PageContainer>
    </>
  );
};

export default Home;
