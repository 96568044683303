import { Box, Container, Stack, Typography } from '@mui/material';
import { Alert, FeedbackForm } from '../../components';
import { useCreateFeedback } from '../../queries';
import { FeedbackType } from '../../types';
import { useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { useEffect } from 'react';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const Feedback = () => {
  const { page, track } = useAnalytics();

  const { projectId: _id } = useParams();
  const projectId = _id || '';

  const updateFeedbackMutation = useCreateFeedback();

  const handleSubmit = (data: FeedbackType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'send_feedback',
      page: PAGE.FEEDBACK,
    });
    const payload: FeedbackType = {
      message: data.message,
      projectId: data.projectId,
    };
    updateFeedbackMutation.mutate(payload, {
      onSuccess: () => {
        track(EVENTS.SUCCESS, {
          page: PAGE.FEEDBACK,
          action: 'send_feedback',
        });
      },
      onError: () => {
        track(EVENTS.ERROR, {
          page: PAGE.FEEDBACK,
          action: 'send_feedback',
        });
      },
    });
  };

  useEffect(() => {
    page({ title: PAGE.FEEDBACK });
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">Feedback</Typography>
          </div>
          <Stack spacing={3}>
            <Alert
              isError={updateFeedbackMutation.isError}
              isSuccess={updateFeedbackMutation.isSuccess}
              error={updateFeedbackMutation.error}
              successMessage="Feedback sent successfully"
            />
            <FeedbackForm
              defaultValues={{
                message: '',
                projectId,
              }}
              onSubmit={handleSubmit}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Feedback;
