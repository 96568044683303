import {
  Select,
  SelectProps,
  Stack,
  Typography,
  outlinedInputClasses,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { ControllerRenderProps } from 'react-hook-form';

export interface DropDownWithLabelProps extends SelectProps {
  field: ControllerRenderProps<any, any>;
}

const DropDownWithLabel: React.FC<DropDownWithLabelProps> = ({
  field,
  label,
  children,
  error,
}) => {
  return (
    <Stack
      direction={'row'}
      spacing={0.7}
      alignItems={'center'}
      justifyContent={'flex-end'}
      px={3}
    >
      <Typography variant="subtitle2" px={1} color={blueGrey[300]}>
        {label}
      </Typography>
      <Select
        sx={{
          width: 150,
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: blueGrey[600],
          },
          color: 'white',
        }}
        size="small"
        error={error}
        {...field}
      >
        {children}
      </Select>
    </Stack>
  );
};

export default DropDownWithLabel;
