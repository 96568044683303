import { Outlet } from 'react-router-dom';

import * as s from './styles';
import { Box, Container, IconButton, Typography } from '@mui/material';
import { Logo } from '../../assets';

const Auth: React.FC = () => {
  return (
    <s.Container component="main">
      <s.GridContainer container>
        <s.GridRightItem xs={12} lg={6} item sx={{ mt: { sm: 10, xs: 10 } }}>
          <Container maxWidth="sm">
            <s.GridRightContent>
              <Box mb={4}>
                <IconButton LinkComponent={'a'} href="https://code99.io">
                  <Logo height={160} width={160} />
                </IconButton>
              </Box>
              <Typography
                align="center"
                color="inherit"
                sx={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  mb: 2,
                }}
                variant="h1"
              >
                Welcome to{' '}
                <Box
                  component="a"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                  target="_blank"
                >
                  Code99
                </Box>
              </Typography>
              <Typography align="center" sx={{ mb: 3 }} variant="subtitle1">
                Unlock Limitless Possibilities: Your Code, Transformed by AI and
                Crafted with Nest.js & TypeORM Excellence
              </Typography>
            </s.GridRightContent>
          </Container>
        </s.GridRightItem>
        <s.GridLeftItem xs={12} lg={6} item>
          <s.LogoContainer component="header">
            {/* <Box
              component={NextLink}
              href="/"
              sx={{
                display: 'inline-flex',
                height: 32,
                width: 32,
              }}
            >
              <Logo />
            </Box> */}
          </s.LogoContainer>
          <s.GridLeftContent>
            <Outlet />
          </s.GridLeftContent>
        </s.GridLeftItem>
      </s.GridContainer>
    </s.Container>
  );
};

export default Auth;
