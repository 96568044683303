import { Paper, Stack, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const Container = styled(Paper)`
  width: 250px;
  border-radius: 4px;
`;

const Header = styled(Stack)`
  padding: 8px 16px;
  justify-content: space-between;
  background-color: ${blueGrey[900]};
`;

const Content = styled(Stack)``;

export { Container, Header, Content };
