import {
  Box,
  Button,
  TextField,
  Unstable_Grid2 as Grid,
  Stack,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { CreateProjectType, ErrorType } from '../../types';
import ModalWithTitle from '../ModalWithTitle';
import Alert from '../Alert';
import { indigo } from '../../theme/colors';
import Icon from '../Icon';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS } from '../../constants/analyticsEvent';

export interface ProjectDetailsModalProps {
  open: boolean;
  defaultValues?: CreateProjectType;
  onSubmit: (data: CreateProjectType) => void;
  onCancel: () => void;
  error?: ErrorType;
  pageName: string;
  isEdit?: boolean;
}

const NewProjectSchema = yup.object<CreateProjectType>().shape({
  name: yup
    .string()
    .max(50, 'Project Name must be at most 50 characters')
    .required('Project Name is required'),
  isGenerateSchema: yup.boolean(),
  description: yup.string().when('isGenerateSchema', ([isGenerateSchema]) => {
    if (isGenerateSchema) {
      return yup
        .string()
        .min(30, 'Project description has at least 30 characters')
        .max(1500, 'Project Description must be at most 1500 characters')
        .required('Project Description is required');
    } else {
      return yup.string().nullable();
    }
  }),
});

const ProjectDetailsModal: React.FC<ProjectDetailsModalProps> = ({
  open,
  defaultValues,
  onSubmit,
  onCancel,
  error,
  pageName,
  isEdit,
}) => {
  const { track } = useAnalytics();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<CreateProjectType>({
    defaultValues,
    resolver: yupResolver(NewProjectSchema),
  });

  const handleCreateBlankProject = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'create_blank_project',
      page: pageName,
    });
    setValue('isGenerateSchema', false);
    handleSubmit(onSubmit)();
  };

  const handleCreateWithAISchema = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'create_with_ai_schema',
      page: pageName,
    });
    setValue('isGenerateSchema', true);
    handleSubmit(onSubmit)();
  };

  return (
    <ModalWithTitle
      open={open}
      title={isEdit ? 'Edit Project' : 'New Project'}
      subTitle={
        isEdit ? 'Edit project information' : 'Add new project information'
      }
      form={{
        onSubmit: () => {},
      }}
      renderAction={() => (
        <Stack spacing={2} width={'100%'}>
          {error && <Alert error={error} isError />}
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'}>
            <Button
              onClick={onCancel}
              size="small"
              sx={{ color: indigo.light }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleCreateBlankProject}
            >
              {isEdit ? 'Update Project' : 'Create Blank Project'}
            </Button>
            {!isEdit && (
              <Button
                variant="contained"
                size="small"
                color="success"
                onClick={handleCreateWithAISchema}
                startIcon={<Icon iconName="bolt" size={24} color="black" />}
              >
                Create With AI Schema
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    >
      <Box width={560}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue={defaultValues?.name || ''}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Project Name"
                  {...field}
                  error={!!errors.name?.message}
                  helperText={
                    errors.name?.message || `${getValues('name').length}/50`
                  }
                />
              )}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue={defaultValues?.description || ''}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Description"
                  placeholder="Ex. Create a feature-rich blogging platform that allows users to register accounts, write and publish blog posts, manage drafts, categorize posts with tags, enable comments and likes on posts, provide user profiles, implement a search functionality, and support rich media embedding for an engaging and interactive blogging experience."
                  {...field}
                  error={!!errors.description?.message}
                  helperText={
                    errors.description?.message ||
                    `${getValues('description')?.length}/1500`
                  }
                  inputProps={{
                    style: {
                      height: 200,
                      overflow: 'scroll',
                    },
                  }}
                  multiline
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </ModalWithTitle>
  );
};

export default ProjectDetailsModal;
