import { DefaultEdgeOptions, Edge, MarkerType, Node } from 'reactflow';
import dagre from 'dagre';
import { JsonDBTableType, JsonDBType } from '../types';

export const defaultEdgeOptions: DefaultEdgeOptions = {
  type: 'smoothstep',
  labelBgPadding: [12, 6],
  deletable: false,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
  },
  style: {
    strokeWidth: 1.5,
  },
  labelStyle: {
    fontSize: 14,
  },
};

export const getNodesAndEdgesFromJsonDB = (jsonDB: JsonDBType) => {
  const tables = jsonDB.tables;

  let tableNodes: Node<JsonDBTableType>[] = [];
  const relationEdges: Edge[] = [];

  const tableIndex: Record<string, number> = {};

  tables.forEach((table, index) => {
    tableIndex[table.name] = index;
  });

  tables.forEach((table, index) => {
    table.cols.forEach((column) => {
      if (column.fk) {
        relationEdges.push({
          id: `${column.fk.table}.${column.fk.refCol}-${table.name}.${column.name}`,
          source: `${tableIndex[column.fk.table]}`,
          target: `${tableIndex[table.name]}`,
          sourceHandle: `${column.fk.table}.${column.fk.refCol}`,
          targetHandle: `${table.name}.${column.name}`,
          label: column.fk.type,
        });
      }
    });

    tableNodes.push({
      id: `${index}`,
      type: 'dbTable',
      data: table,
      position: {
        x: 0,
        y: 0,
      },
    });
  });

  // Arrange Node using dagre
  const g = new dagre.graphlib.Graph();

  // Set an object for the graph label
  g.setGraph({ rankdir: 'LR', nodesep: 300, edgesep: 50, ranksep: 500 });

  // Default to assigning a new object as a label for each new edge.
  g.setDefaultEdgeLabel(function () {
    return {};
  });

  tableNodes.forEach((x) => g.setNode(x.id, x));
  relationEdges.forEach((x) => g.setEdge(x.source, x.target));

  dagre.layout(g);

  tableNodes = tableNodes.map((node) => {
    const { x, y } = g.node(node.id);
    node.position.x = x;
    node.position.y = y;
    return node;
  });

  return {
    tableNodes,
    relationEdges,
  };
};

export const getUpdatedEdges = (
  nodes: Node<JsonDBTableType>[],
  updatedTable: JsonDBTableType
): Edge[] => {
  const updatedEdges: Edge[] = [];

  const updatedTableFK = updatedTable.cols.filter((x) => !!x.fk);

  const nodeIndex: Record<string, number> = {};

  nodes.forEach((node, index) => {
    nodeIndex[node.data.name] = index;
  });

  updatedTableFK.forEach((column) => {
    nodes.forEach((node) => {
      if (column.fk?.table === node.data.name) {
        updatedEdges.push({
          id: `${column.fk.table}.${column.fk.refCol}-${updatedTable.name}.${column.name}`,
          source: `${nodeIndex[column.fk.table]}`,
          target: `${nodeIndex[updatedTable.name]}`,
          sourceHandle: `${column.fk.table}.${column.fk.refCol}`,
          targetHandle: `${updatedTable.name}.${column.name}`,
          label: column.fk.type,
        });
      }
    });
  });

  return updatedEdges;
};

export const removeRefEdges = (
  id: string,
  edges: Edge[],
  type: 'source' | 'target' | 'both' = 'both'
): Edge[] => {
  return [
    ...edges.filter((edge) => {
      if (type === 'source') {
        return edge.source !== id;
      } else if (type === 'target') {
        return edge.target !== id;
      } else {
        return edge.source !== id && edge.target !== id;
      }
    }),
  ];
};
