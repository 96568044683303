import { Alert, Box, Link as MuiLink } from '@mui/material';
import { useMyAccount } from '../../queries';
import { Link } from 'react-router-dom';

const SubscriptionAlert = () => {
  const { data: myAccount } = useMyAccount(false);

  if (myAccount?.cgCredit) {
    return null;
  }

  return (
    <Box mt={4}>
      <Alert
        variant="standard"
        severity="warning"
        action={
          <MuiLink
            component={Link}
            to={'https://code99.io'}
            sx={{
              color: 'black',
              fontWeight: '600',
              textDecoration: 'none',
              mr: 1,
              backgroundColor: (theme) => theme.palette.warning.main,
              py: 1,
              px: 2,
              borderRadius: 1,
            }}
          >
            Purchase
          </MuiLink>
        }
        sx={{
          alignItems: 'center',
        }}
      >
        You do not have enough credit for code generation, You need to purchase
        credit for code generation.
      </Alert>
    </Box>
  );
};

export default SubscriptionAlert;
