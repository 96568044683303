import { Box, Tooltip, alpha } from '@mui/material';

export interface IconProps {
  size?: number;
  iconName: string;
  tooltip?: string;
  color?: string;
  fill?: boolean;
}

const Icon: React.FC<IconProps> = ({
  iconName,
  size = 32,
  tooltip,
  color,
  fill = true,
}) => {
  return (
    <Tooltip title={tooltip || ''}>
      <Box
        component={'span'}
        className={'material-symbols-rounded'}
        style={
          fill
            ? {
                fontVariationSettings: '"FILL" 1',
              }
            : undefined
        }
        sx={{
          fontSize: size,
          color: color || ((theme) => alpha(theme.palette.text.primary, 0.65)),
        }}
      >
        {iconName}
      </Box>
    </Tooltip>
  );
};

export default Icon;
