import { useQueryClient } from '@tanstack/react-query';
import { Navigate, Outlet } from 'react-router-dom';
import { appRoutes, common } from '../../constants';
import { useEffect } from 'react';

const UnAuthenticateGuard = () => {
  const queryClient = useQueryClient();
  const accessToken = localStorage.getItem(common.KEY_ACCESS_TOKEN);

  useEffect(() => {
    if (accessToken) {
      queryClient.setQueryData([common.KEY_ACCESS_TOKEN], () => accessToken);
    } else {
      queryClient.invalidateQueries([common.KEY_ACCESS_TOKEN]);
    }
  }, [accessToken]);

  if (!accessToken) {
    return <Outlet />;
  } else {
    return <Navigate to={appRoutes.HOME} />;
  }
};

export default UnAuthenticateGuard;
