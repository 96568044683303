import useAuthenticatedMutation from '../hook/useAuthenticateMutation';
import useAuthenticatedQuery from '../hook/useAuthenticatedQuery';
import queryAsync from '../network/apiClient';
import { ProjectType } from '../types';

export const useProjects = (page: number) => {
  return useAuthenticatedQuery(
    ['projects', `${page}`],
    (token) => {
      return queryAsync<{
        result: ProjectType[];
        total: number;
      }>({
        path: `/projects?page=1&limit=100`,
        type: 'Get',
        token,
      });
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useCreateProject = () => {
  return useAuthenticatedMutation(['create-project'], (token, params) => {
    return queryAsync<ProjectType>({
      path: `/projects`,
      type: 'POST',
      data: { ...params },
      token,
    });
  });
};

export const useProjectDetails = (
  projectId: string,
  stopRefetching: boolean = false
) => {
  return useAuthenticatedQuery(
    ['project-details', `${projectId}`],
    (token) => {
      return queryAsync<ProjectType>({
        path: `/projects/${projectId}`,
        type: 'Get',
        token,
      });
    },
    {
      refetchInterval(data) {
        if (stopRefetching) {
          return false;
        }
        return data?.status === 1 || data?.status === -1 ? false : 3000;
      },
    }
  );
};

export const useDeleteProject = (projectId: string) => {
  return useAuthenticatedMutation(['delete-project'], (token) => {
    return queryAsync<ProjectType>({
      path: `/projects/${projectId}`,
      type: 'DELETE',
      token,
    });
  });
};

export const useUpdateProjectDetails = (projectId: string) => {
  return useAuthenticatedMutation(
    ['project-details', `${projectId}`],
    (token, params) => {
      return queryAsync<ProjectType>({
        path: `/projects/${projectId}`,
        type: 'PUT',
        token,
        data: {
          ...params,
        },
      });
    }
  );
};

export const useGenerateProjectSchema = (projectId: string) => {
  return useAuthenticatedMutation(
    ['project-details', `${projectId}`],
    (token, params) => {
      return queryAsync<ProjectType>({
        path: `/projects/${projectId}/generate-schema`,
        type: 'PUT',
        token,
        data: {
          ...params,
        },
      });
    }
  );
};
