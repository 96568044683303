// Open Routes
const LOGIN = '/login';
const SIGN_UP = '/register';
const SIGN_UP_ROUTE = '/register/:checkoutSessionId?';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/reset-password';
const VERIFY_EMAIL = '/verify-email';

// Authorized Routes
const HOME = '/';
const NEW_PROJECT = '/new-project';
const PROJECT_DETAILS = '/:projectId';
const PROJECT_CODE = '/:projectId/code';
const MY_ACCOUNT = '/my-account';
const FEEDBACK = '/feedback/:projectId';

export default {
  LOGIN,
  SIGN_UP,
  SIGN_UP_ROUTE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  HOME,
  NEW_PROJECT,
  PROJECT_DETAILS,
  PROJECT_CODE,
  MY_ACCOUNT,
  FEEDBACK,
};
