import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { AppBar, Box, Container, Menu, MenuItem, Stack } from '@mui/material';
import * as s from './styles';
import { useSingOut } from '../../queries';
import Icon from '../Icon';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../constants';
import { Logo } from '../../assets';

const LOGO_SIZE = 40;

const Header: React.FC = () => {
  const navigate = useNavigate();

  const logoutMutation = useSingOut();

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(accountMenuAnchorEl);

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const handleMyAccount = () => {
    navigate(appRoutes.MY_ACCOUNT);
    handleAccountMenuClose();
  };

  const handleHome = () => {
    navigate(appRoutes.HOME);
    handleAccountMenuClose();
  };

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  const accountMenuId = 'account-menu';
  const renderAccountMenu = (
    <Menu
      PaperProps={{
        elevation: 1,
        sx: s.styledForAccountMenu,
      }}
      anchorEl={accountMenuAnchorEl}
      id={accountMenuId}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      open={isAccountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <MenuItem onClick={handleHome}>Projects</MenuItem>
      <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" sx={{ borderRadius: 50, mt: 4 }}>
        <Container maxWidth="xl" sx={{ paddingLeft: '8px !important' }}>
          <Toolbar disableGutters>
            <Stack
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                flex: 1,
              }}
              direction={'row'}
              spacing={2}
              alignItems={'center'}
            >
              <IconButton onClick={handleHome} disableRipple>
                <Logo height={LOGO_SIZE} width={LOGO_SIZE} />
              </IconButton>
            </Stack>
            <Box
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
              }}
            >
              <Logo height={LOGO_SIZE} width={LOGO_SIZE} />
            </Box>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={accountMenuId}
                aria-haspopup="true"
                onClick={handleAccountMenuOpen}
              >
                <Icon iconName="account_circle" />
              </IconButton>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      {renderAccountMenu}
    </>
  );
};

export default Header;
