import { Modal, Stack, Typography } from '@mui/material';
import * as s from './styles';
import CircularProgress from '../CircularProgress';

export interface LoaderWithModalProps {
  text: string;
  open: boolean;
}

const LoaderWithModal: React.FC<LoaderWithModalProps> = ({ text, open }) => {
  return (
    <Modal open={open} disableAutoFocus disableEnforceFocus disableRestoreFocus>
      <s.Container>
        <Stack p={4}>
          <CircularProgress />
          <Typography>{text}</Typography>
        </Stack>
      </s.Container>
    </Modal>
  );
};

export default LoaderWithModal;
