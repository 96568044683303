import {
  Card,
  CardContent,
  IconButton as MuiIconButton,
  SxProps,
  styled,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 240px;
  border-radius: 10px;
  :hover {
    cursor: pointer;
    box-shadow: 4px 4px 8px ${(props) => props.theme.palette.primary.light};
    transform: scale(1.025);
    transition: all 0.2s ease-in-out;
  }
`;

const Content = styled(CardContent)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(3)};
`;

const styledForName: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
};

const styledForDescription: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
};

const styledForNoDescription: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  color: blueGrey[100],
  fontStyle: 'italic',
};

const IconButton = styled(MuiIconButton)`
  background-color: ${(props) => props.theme.palette.primary.main};
  :hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

export {
  Container,
  styledForDescription,
  styledForNoDescription,
  Content,
  styledForName,
  IconButton,
};
