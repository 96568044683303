const EVENTS = {
  CLICK: 'click',
  SUCCESS: 'success',
  ERROR: 'error',
};

const CATEGORY = {
  BUTTON: 'button',
  LINK: 'link',
  CARD: 'card',
  ICON_BUTTON: 'icon_button',
};

const PAGE = {
  HOME: 'home',
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
  VERIFY_EMAIL: 'verify_email',
  FEEDBACK: 'feedback',
  MY_ACCOUNT: 'my_account',
  NEW_PROJECT: 'new_project',
  PROJECT_DETAILS: 'project_details',
  PROJECT_CODE: 'project_code',
};

export { EVENTS, CATEGORY, PAGE };
