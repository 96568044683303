import { Alert, Grid, Typography, Link as MuiLink } from '@mui/material';
import * as s from './styles';
import { useVerifyEmail } from '../../queries';
import { appRoutes } from '../../constants';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS, PAGE } from '../../constants/analyticsEvent';

const VerifyEmail = () => {
  const { page, track } = useAnalytics();
  const { search } = useLocation();
  const emailVerifyToken = new URLSearchParams(search).get('token') || '';

  const emailVerifyMutation = useVerifyEmail();

  useEffect(() => {
    if (emailVerifyToken) {
      emailVerifyMutation.mutate(emailVerifyToken, {
        onSuccess: () => {
          track(EVENTS.SUCCESS, {
            page: PAGE.VERIFY_EMAIL,
            action: 'verify_email',
          });
        },
        onError: () => {
          track(EVENTS.ERROR, {
            page: PAGE.VERIFY_EMAIL,
            action: 'verify_email',
          });
        },
      });
    }
  }, [emailVerifyToken]);

  useEffect(() => {
    page({ title: PAGE.VERIFY_EMAIL });
  }, []);

  return (
    <s.Container>
      <Typography variant="h4">Verified!</Typography>
      <s.FormWrapper>
        {emailVerifyMutation.isError && (
          <Alert severity="error">
            {emailVerifyMutation.error.errorMessage}
          </Alert>
        )}
        {emailVerifyMutation.isSuccess && (
          <Alert severity="success">Your email verified successfully</Alert>
        )}
        <Grid container>
          <Grid item xs>
            <MuiLink
              component={Link}
              to={appRoutes.LOGIN}
              underline="none"
              onClick={() =>
                track(EVENTS.CLICK, {
                  category: CATEGORY.LINK,
                  label: 'back_to_login',
                  page: PAGE.VERIFY_EMAIL,
                })
              }
            >
              Back to Login
            </MuiLink>
          </Grid>
        </Grid>
      </s.FormWrapper>
    </s.Container>
  );
};

export default VerifyEmail;
