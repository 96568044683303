import { memo } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';

import * as s from './styles';
import Icon from '../../Icon';

export interface DBTableListRowType {
  tableName: string;
  canNotDelete?: boolean;
  onRowClick: () => void;
  onDelete: () => void;
}

const DBTableListRow: React.FC<DBTableListRowType> = ({
  tableName,
  canNotDelete,
  onRowClick,
  onDelete,
}) => {
  return (
    <s.Container direction={'row'}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        onClick={onRowClick}
        flex={1}
      >
        <Typography fontSize={14}>{tableName}</Typography>
      </Stack>
      {!canNotDelete && (
        <IconButton size="small" onClick={onDelete}>
          <Icon tooltip={`Delete ${tableName}`} iconName="close" size={16} />
        </IconButton>
      )}
    </s.Container>
  );
};

export default memo(DBTableListRow);
