import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components';
import * as s from './styles';
import { Stack } from '@mui/material';

const Dashboard = (): React.ReactElement => {
  return (
    <s.Root>
      <s.Content maxWidth="lg">
        <Stack height={'100vh'}>
          <Header />
          <Outlet />
        </Stack>
      </s.Content>
    </s.Root>
  );
};

export default Dashboard;
