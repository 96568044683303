import { CardActions, Stack, Typography } from '@mui/material';
import { ProjectType } from '../../types';

import * as s from './styles';
import Icon from '../Icon';

export interface ProjectCardProps {
  project: ProjectType;
  onClick: () => void;
  onCodeClick: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  onClick,
  onCodeClick,
}) => {
  const handleCodeClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onCodeClick();
  };

  return (
    <s.Container variant="outlined" onClick={onClick}>
      <s.Content>
        <Stack display={'flex'} flex={1}>
          <Typography gutterBottom variant="h6" mb={3} sx={s.styledForName}>
            {project.name}
          </Typography>
          {project.description ? (
            <Typography sx={s.styledForDescription} variant="body2">
              {project.description}
            </Typography>
          ) : (
            <Typography sx={s.styledForNoDescription} variant="body2">
              No description
            </Typography>
          )}
        </Stack>
      </s.Content>
      <CardActions>
        <Stack
          direction={'row-reverse'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flex={1}
        >
          <s.IconButton onClick={handleCodeClick}>
            <Icon iconName="code" size={20} color="white" />
          </s.IconButton>
        </Stack>
      </CardActions>
    </s.Container>
  );
};

export default ProjectCard;
