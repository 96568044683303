import { Box, BoxProps, Grid, styled } from '@mui/material';

type ContainerProps = {
  background?: string;
} & BoxProps;

const Container = styled(Grid)<ContainerProps>`
  border-radius: 38px;
  background: ${(props) =>
    props.background ||
    'radial-gradient(70.71% 70.71% at 50% 50%, rgba(60, 79, 137, 0.31) 0%, rgba(57, 75, 132, 0.11) 100%)'};
  box-shadow: 0px 0px 0px 1px rgba(225, 248, 255, 0.09) inset;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ImageWrapper = styled(Box)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled('img')`
  width: 100%;
  border-radius: 38px;
`;

export { Container, ImageWrapper, Image };
