import { Route, Routes } from 'react-router-dom';
import { appRoutes } from '../constants';
import { Auth, Dashboard } from '../layout';
import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  Home,
  NewProject,
  MyAccount,
  Feedback,
  ProjectDetails,
  ProjectCode,
  VerifyEmail,
} from '../pages';
import { AuthenticateGuard, UnAuthenticateGuard } from '../components';

const AppRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<AuthenticateGuard />}>
        <Route element={<Dashboard />}>
          <Route path={appRoutes.HOME} element={<Home />} />
          <Route path={appRoutes.MY_ACCOUNT} element={<MyAccount />} />
          <Route path={appRoutes.FEEDBACK} element={<Feedback />} />
          <Route path={appRoutes.PROJECT_CODE} element={<ProjectCode />} />
        </Route>
        <Route path={appRoutes.NEW_PROJECT} element={<NewProject />} />
        <Route path={appRoutes.PROJECT_DETAILS} element={<ProjectDetails />} />
      </Route>
      <Route element={<UnAuthenticateGuard />}>
        <Route element={<Auth />}>
          <Route path={appRoutes.LOGIN} element={<Login />} />
          <Route path={appRoutes.SIGN_UP_ROUTE} element={<Register />} />
          <Route
            path={appRoutes.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path={appRoutes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={appRoutes.VERIFY_EMAIL} element={<VerifyEmail />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
