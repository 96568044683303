import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
  outlinedInputClasses,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import { memo } from 'react';
import { JsonDBTableColumnType, JsonDBTableType } from '../../../types';
import Icon from '../../Icon';

export interface DBEditTableColumnProps {
  index: number;
  column: JsonDBTableColumnType;
  control: Control<JsonDBTableType, any>;
  errors:
    | Merge<
        FieldError,
        (
          | Merge<FieldError, FieldErrorsImpl<JsonDBTableColumnType>>
          | undefined
        )[]
      >
    | undefined;
  onDeleteColumn: () => void;
  onFK: () => void;
}

const columnType = [
  'integer',
  'decimal',
  'varchar',
  'text',
  'boolean',
  'date',
  'time',
  'datetime',
];

const DBEditTableColumn: React.FC<DBEditTableColumnProps> = ({
  index,
  column,
  control,
  errors,
  onDeleteColumn,
  onFK,
}) => {
  const defaultConfigured = !!column.defaultType;

  return (
    <TableRow>
      <TableCell>
        <Controller
          control={control}
          name={`cols.${index}.name`}
          defaultValue={column.name}
          render={({ field }) => (
            <TextField
              variant="outlined"
              size="small"
              sx={{
                width: 150,
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: blueGrey[600],
                },
              }}
              error={
                errors &&
                (!!errors[index]?.name ||
                  errors[index]?.type === 'unique-name-error')
              }
              helperText={
                errors &&
                (errors[index]?.name?.message ||
                  (errors[index]?.type === 'unique-name-error' &&
                    errors[index]?.message))
              }
              disabled={defaultConfigured}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`cols.${index}.type`}
          defaultValue={column.fk?.type || column.type}
          render={({ field }) => (
            <Select
              sx={{
                width: 150,
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: blueGrey[600],
                },
              }}
              size="small"
              error={
                errors &&
                !!errors[index]?.type &&
                errors[index]?.type !== 'unique-name-error'
              }
              disabled={defaultConfigured || !!column.fk}
              {...field}
            >
              {columnType.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </TableCell>
      <TableCell>
        <IconButton
          sx={{
            display: defaultConfigured ? 'none' : 'flex',
            alignSelf: 'center',
          }}
          onClick={onFK}
          disabled={defaultConfigured}
          disableRipple
        >
          {column.fk ? (
            <Icon iconName="edit" size={26} />
          ) : (
            <Icon iconName="add" size={26} />
          )}
        </IconButton>
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`cols.${index}.index`}
          defaultValue={column.index || false}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={defaultConfigured}
              sx={{
                display: defaultConfigured ? 'none' : 'flex',
              }}
              disableRipple
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`cols.${index}.required`}
          defaultValue={column.required || false}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              disabled={defaultConfigured}
              sx={{
                display: defaultConfigured ? 'none' : 'flex',
              }}
              disableRipple
            />
          )}
        />
      </TableCell>
      <TableCell>
        <IconButton
          sx={{
            alignSelf: 'center',
            display: defaultConfigured ? 'none' : 'flex',
          }}
          onClick={onDeleteColumn}
          disabled={defaultConfigured}
          disableRipple
        >
          <Icon iconName="do_not_disturb_on" size={24} />
        </IconButton>
      </TableCell>
      <TableCell>
        <Box sx={{ width: 140 }}>
          <Typography variant="caption" fontStyle={'italic'}>
            {column.defaultType?.note}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default memo(DBEditTableColumn);
