import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
// import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnalyticsProvider } from 'use-analytics';
import * as Sentry from '@sentry/react';
import { createTheme } from './theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import analytics from './utils/analytics';
import { CookiesProvider, useCookies } from 'react-cookie';
import { getUniqueId } from './utils/common';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

Sentry.init({
  dsn: 'https://3d19aa29459cf0accd610e9dd2129e7b@o4507672559091712.ingest.us.sentry.io/4507672560795648',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.code99\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// const localStoragePersister = createSyncStoragePersister({
//   storage: window.localStorage,
// });

// // persistQueryClient({
// //   queryClient,
// //   persister: localStoragePersister,
// // });

const theme = createTheme();

const App = (): React.ReactElement => {
  const [cookies, setCookie] = useCookies(['_anaId']);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let userId = cookies._anaId;
      if (!userId) {
        userId = getUniqueId();
        setCookie('_anaId', userId, {
          domain: import.meta.env.DEV ? 'localhost' : '.code99.io',
        });
      }
      analytics.identify(userId);
    }
  }, []);

  return (
    <AnalyticsProvider instance={analytics}>
      <CookiesProvider
        defaultSetOptions={{
          domain: import.meta.env.DEV ? 'localhost' : '.code99.io',
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <CssBaseline />
              <GoogleOAuthProvider clientId="1069339003764-q6h6kk2vler5linfbo1pjvvgmue8adaf.apps.googleusercontent.com">
                <AppRoutes />
              </GoogleOAuthProvider>
            </BrowserRouter>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </AnalyticsProvider>
  );
};

export default App;
