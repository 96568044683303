import { error, indigo, info, neutral, success, warning } from './colors';
import { ThemeOptions, alpha } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']; // You can also use Palette['secondary'] if you prefer
  }
  interface PaletteOptions {
    neutral?: PaletteOptions['primary']; // You can also use PaletteOptions['secondary'] if you prefer
  }
}

export function createPalette(): ThemeOptions['palette'] {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[50], 0.38),
      disabledBackground: alpha(neutral[50], 0.12),
      focus: alpha(neutral[50], 0.16),
      hover: alpha(neutral[50], 0.04),
      selected: alpha(neutral[50], 0.12),
    },
    background: {
      paper: '#1f2329',
    },
    divider: '#323232',
    error,
    info,
    mode: 'dark',
    neutral,
    primary: indigo,
    success,
    text: {
      primary: neutral[50],
      secondary: neutral[500],
      disabled: alpha(neutral[50], 0.38),
    },
    warning,
  };
}
