import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import DBEditTableColumn from '../DBEditTableColumn';
import ModalWithTitle from '../../ModalWithTitle';
import { useEffect, useRef, useState } from 'react';
import { useDiagramSlice } from '../../../store';
import { JsonDBReferenceColumnType, JsonDBTableType } from '../../../types';
import { cleanTableKeys, getSchemaForEditTable } from '../../../utils';
import DBRelationModal from '../DBRelationModal';
import { indigo } from '../../../theme/colors';
import { useAnalytics } from 'use-analytics';
import { CATEGORY, EVENTS } from '../../../constants/analyticsEvent';

export interface DBEditTableModalProps {
  open: boolean;
  onClose: () => void;
  onSaveChanges: (updatedTable: JsonDBTableType) => void;
  onDeleteTable: () => void;
  pageName: string;
}

const DBEditTableModal: React.FC<DBEditTableModalProps> = ({
  open,
  onClose,
  onSaveChanges,
  onDeleteTable,
  pageName,
}) => {
  const { track } = useAnalytics();

  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const isNewColumnAdded = useRef(false);

  const nodes = useDiagramSlice((state) => state.nodes);
  const _table = useDiagramSlice((state) => state.getSelectedNode())?.data;

  if (!_table) {
    throw new Error('Table is undefined');
  }

  const [selectedFKIndex, setSelectedFKIndex] = useState<number | undefined>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<JsonDBTableType>({
    defaultValues: _table,
    resolver: yupResolver(
      getSchemaForEditTable(nodes.filter((x) => x.data.name !== _table.name))
    ),
  });

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'cols',
  });

  const handleSaveChanges = (updatedTable: JsonDBTableType) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'save_changes',
      page: pageName,
    });

    const cleanTableUpdatedTable = cleanTableKeys(updatedTable);
    onSaveChanges(cleanTableUpdatedTable);
  };

  const handleFk = (index: number) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.ICON_BUTTON,
      page: pageName,
      label: 'icon_fk',
    });

    setSelectedFKIndex(index);
  };

  const handleCloseFkModal = () => {
    setSelectedFKIndex(undefined);
  };

  const handleFKChanges = (
    data: JsonDBReferenceColumnType | undefined = undefined
  ) => {
    if (selectedFKIndex !== undefined) {
      const cols = getValues('cols');
      cols[selectedFKIndex].fk = data;
      if (data) {
        const refTable = nodes.find((x) => x.data.name === data.table);
        if (refTable) {
          const refCol = refTable.data.cols.find((x) => x.name === data.refCol);
          if (refCol) {
            cols[selectedFKIndex].type = refCol.type;
          }
        }
      }
      // Now you can update your state or perform any other necessary actions
      setValue('cols', cols);
      handleCloseFkModal();
    }
  };

  const handleCancel = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      label: 'cancel',
      page: pageName,
    });
    onClose();
  };

  const handleAddNewColumn = () => {
    track(EVENTS.CLICK, {
      category: CATEGORY.BUTTON,
      page: pageName,
      label: 'add_new_column',
    });
    append({ name: 'newColumn', type: 'varchar' });
    isNewColumnAdded.current = true;
  };

  const handleDeleteColumn = (index: number) => {
    track(EVENTS.CLICK, {
      category: CATEGORY.ICON_BUTTON,
      page: pageName,
      label: 'icon_delete',
    });
    remove(index);
  };

  useEffect(() => {
    if (isNewColumnAdded.current) {
      // Scroll to the end of the table
      if (tableBodyRef.current) {
        const rows = tableBodyRef.current.getElementsByTagName('tr');
        const lastRow = rows[rows.length - 1];
        lastRow.scrollIntoView({ behavior: 'smooth' });
      }
      isNewColumnAdded.current = false;
    }
  }, [fields]);

  const renderAction = () => (
    <Stack
      flex={1}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleAddNewColumn}
          disableRipple
        >
          Add New Column
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={onDeleteTable}
          sx={{ display: _table.canNotDelete ? 'none' : 'inherit' }}
          disabled={_table.canNotDelete}
          disableRipple
        >
          Delete Table
        </Button>
      </Stack>
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <Button
          onClick={handleCancel}
          size="small"
          disableRipple
          sx={{ color: indigo.light }}
        >
          Cancel
        </Button>
        <Button variant="contained" size="small" type="submit" disableRipple>
          Save Changes
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <>
      <ModalWithTitle
        open={open}
        title="Edit Table"
        subTitle="Edit table column Name, Type, FK etc."
        form={{
          onSubmit: handleSubmit(handleSaveChanges),
        }}
        renderAction={renderAction}
        disableContentPadding
      >
        <Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={2} p={2.5}>
            <Typography>Table Name : </Typography>
            <Controller
              name="name"
              control={control}
              defaultValue={_table.name}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  {...field}
                  disabled={_table.canNotDelete}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Stack>
          <TableContainer sx={{ maxHeight: 460 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">FK</TableCell>
                  <TableCell align="center">Index</TableCell>
                  <TableCell align="center">Required</TableCell>
                  <TableCell align="center">Delete</TableCell>
                  <TableCell align="center">Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={tableBodyRef}>
                {fields.map((column, index) => (
                  <DBEditTableColumn
                    key={`${Math.random()}`}
                    index={index}
                    control={control}
                    column={column}
                    errors={errors.cols}
                    onDeleteColumn={() => handleDeleteColumn(index)}
                    onFK={() => handleFk(index)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </ModalWithTitle>
      {selectedFKIndex !== undefined && (
        <DBRelationModal
          open={selectedFKIndex !== undefined}
          onClose={handleCloseFkModal}
          onDeleteKey={() => handleFKChanges()}
          onSaveChanges={handleFKChanges}
          defaultValues={getValues(`cols.${selectedFKIndex}`)?.fk}
          currentTableName={_table.name}
        />
      )}
    </>
  );
};

export default DBEditTableModal;
