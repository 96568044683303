import { Box, Container, styled } from '@mui/material';

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Content = styled(Container)`
  flex-grow: 1;
  height: 100%;
`;

export { Root, Content };
