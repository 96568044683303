import { useMutation, useQuery } from '@tanstack/react-query';
import queryAsync from '../network/apiClient';
import { ErrorType } from '../types';

export const useCheckoutSession = (checkoutSessionId?: string) => {
  return useQuery<{ name: string; email: string }, ErrorType>(
    ['checkout-session-info'],
    () => {
      return queryAsync<{ name: string; email: string }>({
        path: `/stripe/session/${checkoutSessionId}`,
        type: 'GET',
      });
    },
    {
      enabled: !!checkoutSessionId,
    }
  );
};

export const useStripeRegister = () => {
  return useMutation<
    string,
    { status: string; errorCode: string; errorMessage: string },
    object,
    unknown
  >((params: object) => {
    return queryAsync<string>({
      path: `/stripe/register`,
      type: 'POST',
      data: { ...params },
    });
  });
};
