import { Button as MuiButton, Paper, Stack, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const Container = styled(Paper)`
  width: 250px;
  border-radius: 4px;
  overflow: hidden;
`;

const Header = styled(Stack)`
  width: 100%;
  justify-content: space-between;
`;

const Content = styled(Stack)`
  background-color: ${blueGrey[800]};
`;

const Button = styled(MuiButton)`
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-left: ${(props) => props.theme.spacing(2)};
`;

export { Container, Header, Content, Button };
